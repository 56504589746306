import { Component, OnInit } from '@angular/core';
import { Ingredient } from '../../models/ingredient';
import { WebService } from '../../core/web.service';
import { Security } from '~auth/security';

@Component({
  selector: 'app-quick-edit-ingredients',
  templateUrl: './quick-edit-ingredients.component.html',
  styleUrls: ['./quick-edit-ingredients.component.css'],
})
export class QuickEditIngredientsComponent implements OnInit {
  ingredients: Ingredient[];

  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {
    //load ingredients
    this.webService
      .getIngredients()
      .subscribe((data) => (this.ingredients = data));
  }

  SaveDispNameNl(ing) {
    if (this.security.notLoggedIn()) return;
    this.webService.updateIngredient(ing).subscribe((data) => (ing = data));
  }
}
