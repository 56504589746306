<div class="card text-center d-flex align-items-stretch">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-12 text-left" style="min-height: 54px">
        <app-course-logo-icon
          *ngFor="let item of courseLogos"
          [id]="item.id"
          [language]="language"
          [large]="false"
        ></app-course-logo-icon>
        <div style="float: right; text-align: right">
          €{{ displayPrice }}<br />
          {{ extra }}
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" style="min-height: 11em">
    <h5 *ngIf="!security.allowed('Admin,Chef,Keuken')"
      class="card-title"
      [innerHTML]="coursenames"
      style="min-height: 3em"
    ></h5>
    <h5 *ngIf="security.allowed('Admin,Chef,Keuken')" class="card-title">
      <ng-container *ngFor="let c of courseLinks">
        <span [routerLink]="[c.link]" style="cursor:pointer;color:blue;" >
         {{c.name}}
        </span>
      </ng-container>
    </h5>
    <app-allergen-icon
      *ngFor="let item of setCoursesAllergens"
      [id]="item"
      [language]="language"

    ></app-allergen-icon>

    <div
      *ngIf="security.allowed('Admin,Chef')"
      style="padding: 4px; margin-top: 4px; cursor: pointer"
      class="bg-light"
    >
      <div style="float: left; padding-right: 8px">
        <mat-icon
          *ngIf="
            security.allowed('Admin') ||
            (security.allowed('Chef') && menuItem.enabled == 0)
          "
          (click)="editContent(contentCourses)"
          >edit</mat-icon
        >
      </div>
      <div style="float: left" >
        &nbsp;
        <select *ngIf="security.allowed('Admin')"
          [(ngModel)]="menuItem.enabled"
          (change)="onOptionsSelected()"
          style="cursor: pointer"
        >
          <option value="0">aangevraagd</option>
          <option value="1">goedgekeurd</option>
          <option value="2">afgewezen</option>
        </select>
        &nbsp;
        <select *ngIf="security.allowed('Admin,Chef')"
          [(ngModel)]="menuItem.sortorder"
          (change)="onOptionsSelected()"
          style="cursor: pointer"
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">?</option>
          <option value="11">x</option>
        </select>
      </div>
      <div style="float: right" class="text-right">
        <mat-icon
        *ngIf="
        security.allowed('Admin') ||
        (security.allowed('Chef') && menuItem.enabled === 0)
      " (click)="removeThisMenuItem()"
          style="cursor: pointer"
          >delete</mat-icon
        >
      </div>
      <div style="clear: both"></div>
    </div>
    <div
      *ngIf="security.allowed('Admin,Chef')"
      style="padding: 4px"
      [ngClass]="
        menuItem.enabled == 0
          ? 'bg-warning text-white'
          : menuItem.enabled == 1
          ? 'bg-success text-white'
          : 'bg-danger text-white'
      "
    ></div>
  </div>
  <div class="card-footer text-muted text-right" >

<!-- ADD MENU TO COPYLIST -->

<br />
    <mat-checkbox style="float:left;" *ngIf="security.allowed('Admin,Chef')"
    [checked] = "copyValue$ | async"
    (click)="setCopyOnOff($event, mcb);"
    #mcb
    >copy
    </mat-checkbox>

    <span style="float:right;"
      class="badge badge-primary badge-pill"
      (click)="open(content)"
      style="cursor: pointer"
      >{{ ingredientButtonName }}</span
    >
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ ingredientButtonName }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [innerHTML]="ingredienten"></div>
</ng-template>

<ng-template #contentCourses let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Selecteer gerecht(en)</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-courses
      [selectCoursesForMenuItem]="true"
      [setCoursesForMenu]="setCourses"
      [totalPrice]="price"
      (setSelectedCourses)="updateSelectedCourses($event)"
    ></app-courses>
  </div>
</ng-template>
