<input
  type="button"
  onclick="window.print()"
  class="btn btn-danger noprint"
  value="Afdrukken"
/>
<h3>{{ selectedRestaurantName }}</h3>

<div>
  <div
    style="width: 20%; float: left; font-size: 12px"
    *ngFor="let menu of menus"
  >
    <b>{{ getDayOfWeek(menu?.datum) + " " + menu?.datum }}</b
    ><br />

    <div
      *ngFor="let g of menu?.restaurant?.gerecht"
      style="
        border: solid 1px gray;
        margin-right: 8px;
        min-height: 50px;
        background-color: gainsboro;
      "
    >
      {{ g?.naam }}<br />
      € {{ g?.studentprijs }}
      <span *ngIf="g?.meervoudigeprijs == 'True'"
        >/ € {{ g?.personeelsprijs }} </span
      ><br />
      {{ g?.menuinfo }}
    </div>
  </div>
</div>
