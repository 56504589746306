import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { jqxCalendarComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxcalendar';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { CalendarRoutingModule } from './calendar-routing.module';
import { MaterialModule } from '../material/material.module';

import { CalendarComponent } from './calendar.component';
import { MenuitemComponent } from './menuitem/menuitem.component';
import { MenuitemCompositionComponent } from './menuitem-composition/menuitem-composition.component';
import { SharedModule } from '../shared/shared.module';

import { CoursesModule } from '../courses/courses.module';
import { StoreModule } from '@ngrx/store';
import * as fromMenuItemId from './store/menu-item-id/menu-item-id.reducer';

@NgModule({
  declarations: [
    CalendarComponent,
    MenuitemComponent,
    MenuitemCompositionComponent,
    jqxCalendarComponent,
  ],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    CoursesModule,
    MaterialModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromMenuItemId.menuItemIdsFeatureKey, fromMenuItemId.reducer)
  ],
})
export class CalendarModule {}
