import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Security } from '~auth/security';
import { Allergen } from '~models/allergen';
import { Course } from '~models/course';
import { MenuItemContent } from '~models/menu-item-content';
import { WebService } from '~core/web.service';

@Component({
  selector: 'app-menuitem-composition',
  templateUrl: './menuitem-composition.component.html',
  styleUrls: ['./menuitem-composition.component.css'],
})
export class MenuitemCompositionComponent implements OnInit {
  sendId: number;
  menuItemContents: MenuItemContent[] = [];
  courses: Course[];
  coursesInMenuItem: Course[] = [];

  constructor(
    public webService: WebService,
    private route: ActivatedRoute,
    public security: Security,
    private router: Router
  ) {}

  ngOnInit() {
    this.sendId = this.route.snapshot.params['id'];
    this.loadCourses();
  }

  loadCourses() {

      if (!this.security.notLoggedIn()) {
      this.webService
        .getCoursesPLUS()
        .subscribe((data) => this.setCoursesData(data));
      }

  }
  setCoursesData(data) {
    this.courses = data;

  }

  //Start Ng Bootstrap typeahead for adding courses
  modelCourse: any;
  searchCourse = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.courses
              .filter(
                (v) => v.nameNl.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 40)
      )
    );
  iformatterCourse = (x: { nameNl: string }) => x.nameNl;
  rformatterCourse = (result) => result.nameNl;

  addCourseToMenuItem(course: Course) {
    this.coursesInMenuItem.push(course);
  }
}
