import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { Restaurant } from '~models/restaurant';

import { Menu } from '~models/menu';
import { MenuItem } from '~models/menu-item';
import { MenuType } from '~models/menu-type';
import { MenuItemContent } from '~models/menu-item-content';

import { Course } from '~models/course';

import { Course_Allergen } from '~models/course_allergen';
import { Allergen } from '~models/allergen';

import { CourseLogo } from '~models/course-logo';

import { Course_CategoryForCourse } from '~models/course_category-for-course';
import { CategoryForCourse } from '~models/category-for-course';

import { CourseComposition } from '~models/course-composition';
import { CourseCompositionVm } from '~models/course-composition-vm';

import { Ingredient } from '~models/ingredient';

import { Ingredient_Allergen } from '~models/ingredient_allergen';
//allergen already imported

import { Ingredient_CategoryForIngredient } from '~models/ingredient_category-for-ingredient';
import { CategoryForIngredient } from '~models/category-for-ingredient';

import { Todo } from '~models/todo';

import { User } from '~models/user';
import { PriceConversion } from '~models/price-conversion';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';
import { CopyMenuItems } from '~models/copy-menu-items';
import { StringDecoder } from 'string_decoder';

@Injectable({
  providedIn: 'root',
})
export class WebService implements OnInit {
  private url: string = '';
  constructor(private httpClient: HttpClient, public configService: ConfigService) {
    this.url = configService.url();
  }

  ngOnInit() {
     this.url = this.configService.url();
  }
  //CASHED DATA


  private cacheAllergens$: Observable<Array<Allergen>>;

  get cachedAllergens() {
    if (!this.cacheAllergens$) {
      this.cacheAllergens$ = this.getAllergens().pipe(shareReplay(1000));
    }
    return this.cacheAllergens$;
  }

  getAllergens() {
    return this.httpClient
      .get<Allergen[]>(this.url + 'api/GetAllergens')
      .pipe(map((res) => <Allergen[]>res));
  }

  private cacheCourseLogos$: Observable<Array<CourseLogo>>;

  get cachedCourseLogos() {
    if (!this.cacheCourseLogos$) {
      this.cacheCourseLogos$ = this.getCourseLogos().pipe(shareReplay(1000));
    }
    return this.cacheCourseLogos$;
  }

  //DIRECT DATA

  getRestaurants() {
    return this.httpClient
      .get<Restaurant[]>(this.url + 'api/GetRestaurants')
      .pipe(map((res) => <Restaurant[]>res));
  }
  updateRestaurant(restaurant: Restaurant) {
    return this.httpClient
      .post<Restaurant>(this.url + 'api/updateRestaurant', restaurant)
      .pipe(map((res) => <Restaurant>res));
  }
  addRestaurant(restaurant: Restaurant) {
    return this.httpClient
      .post<Restaurant>(this.url + 'api/addRestaurant', restaurant)
      .pipe(map((res) => <Restaurant>res));
  }

  getCourseLogos() {
    return this.httpClient
      .get<CourseLogo[]>(this.url + 'api/GetCourseLogos')
      .pipe(map((res) => <CourseLogo[]>res));
  }

  getCourses() {
    return this.httpClient
      .get<Course[]>(this.url + 'api/GetCourses')
      .pipe(map((res) => <Course[]>res));
  }

  getCourse(id) {
    return this.httpClient.get<Course>(this.url + 'api/GetCourse/' + id);
  }

  getCoursesPLUS() {
    return this.httpClient
      .get<Course[]>(this.url + 'api/GetCoursesPLUS')
      .pipe(map((res) => <Course[]>res));
  }

  getCoursePLUS(id) {
    return this.httpClient.get<Course>(this.url + 'api/GetCoursePLUS/' + id);
  }

  updateCoursePLUS(course: Course) {
    return this.httpClient
      .post<Course>(this.url + 'api/updateCoursePLUS', course)
      .pipe(map((res) => <Course>res));
  }

  getCourseComposition(id) {
    return this.httpClient.get<CourseComposition[]>(
      this.url + 'api/GetCourseComposition/' + id
    );
  }

  getCourseCompositionPlus(id) {
    return this.httpClient.get<CourseCompositionVm[]>(
      this.url + 'api/GetCourseCompositionPlus/' + id
    );
  }

  getAllCoursesUsingIngredient(id) {
    return this.httpClient.get<Course[]>(
      this.url + 'api/Ingredient/GetAllCoursesUsingIngredient/' + id
    );
  }

  addCourseOrIngredientToCourse(CC: CourseComposition) {
    return this.httpClient
      .post<Course>(this.url + 'api/AddCourseOrIngredientToCourse', CC)
      .pipe(map((res) => <Course>res));
  }
  updateCourseOrIngredientAmmount(CC:CourseComposition){
    return this.httpClient
    .post<Boolean>(this.url + 'api/UpdateCourseOrIngredientAmmount',CC)
    .pipe(map((res)=> <Boolean>res))
  }


  removeCourseOrIngredientFromCourse(CC: CourseComposition) {
    return this.httpClient
      .post<CourseComposition>(
        this.url + 'api/RemoveCourseOrIngredientFromCourse',
        CC
      )
      .pipe(map((res) => <CourseComposition>res));
  }

  getCategoryForCourses() {
    return this.httpClient
      .get<CategoryForCourse[]>(this.url + 'api/getCategoryForCourses')
      .pipe(map((res) => <CategoryForCourse[]>res));
  }

  addCategoryToCourse(C_CFC: Course_CategoryForCourse) {
    return this.httpClient
      .post<Course_CategoryForCourse[]>(
        this.url + 'api/AddCategoryToCourse',
        C_CFC
      )
      .pipe(map((res) => <Course_CategoryForCourse[]>res));
  }

  updateCategoryForCourse(category: CategoryForCourse) {
    return this.httpClient
      .post<CategoryForCourse>(
        this.url + 'api/updateCategoryForCourse',
        category
      )
      .pipe(map((res) => <CategoryForCourse>res));
  }

  addCategoryForCourse(category: CategoryForCourse) {
    return this.httpClient
      .post<CategoryForCourse>(this.url + 'api/addCategoryForCourse', category)
      .pipe(map((res) => <CategoryForCourse>res));
  }

  updateCategoryForIngredient(category: CategoryForIngredient) {
    return this.httpClient
      .post<CategoryForIngredient>(
        this.url + 'api/updateCategoryForIngredient',
        category
      )
      .pipe(map((res) => <CategoryForIngredient>res));
  }

  addCategoryForIngredient(category: CategoryForIngredient) {
    return this.httpClient
      .post<CategoryForIngredient>(
        this.url + 'api/addCategoryForIngredient',
        category
      )
      .pipe(map((res) => <CategoryForIngredient>res));
  }

  removeCategoryFromCourse(C_CFC: Course_CategoryForCourse) {
    return this.httpClient
      .post<Course_CategoryForCourse>(
        this.url + 'api/RemoveCategoryFromCourse',
        C_CFC
      )
      .pipe(map((res) => <Course_CategoryForCourse>res));
  }

  getIngredients() {
    return this.httpClient
      .get<Ingredient[]>(this.url + 'api/GetIngredients')
      .pipe(map((res) => <Ingredient[]>res));
  }

  getIngredientsPLUS() {
    return this.httpClient
      .get<Ingredient[]>(this.url + 'api/GetIngredientsPLUS')
      .pipe(map((res) => <Ingredient[]>res));
  }

  getIngredient(id) {
    return this.httpClient.get<Ingredient>(
      this.url + 'api/GetIngredient/' + id
    );
  }

  getIngredientPlus(id) {
    return this.httpClient.get<Ingredient>(
      this.url + 'api/GetIngredientPlus/' + id
    );
  }

  getIngredientContentFromCourse(id) {
    return this.httpClient.get<String>(
      this.url + 'api/GetAllIngredientsFromProduct/' + id
    );
  }

  getCategoryForIngredients() {
    return this.httpClient
      .get<CategoryForIngredient[]>(this.url + 'api/getCategoryForIngredients')
      .pipe(map((res) => <CategoryForIngredient[]>res));
  }

  updateIngredient(ingredient: Ingredient) {
    return this.httpClient
      .post<Ingredient>(this.url + 'api/updateIngredient', ingredient)
      .pipe(map((res) => <Ingredient>res));
  }
  //addIngredient

  //deleteIngredient

  getChefMenu(menuDate, restId) {
    return this.httpClient.get(
      this.url + 'api/GetChefMenu/' + menuDate + '/' + restId
    );
  }

  getMenuByDate(restId, menuDate) {
    return this.httpClient.get<Menu>(
      this.url + 'api/GetMenuByDate/' + restId + '/' + menuDate
    );
  }

  getMenuItem(id) {
    return this.httpClient.get<MenuItem>(this.url + 'api/GetMenuItem/' + id);
  }

  addMenu(menu) {
    return this.httpClient
      .post<Menu>(this.url + 'api/AddMenu', menu)
      .pipe(map((res) => <Menu>res));
  }

  addMenuItem(menu) {
    return this.httpClient
      .post<Menu>(this.url + 'api/AddMenuItem', menu)
      .pipe(map((res) => <Menu>res));
  }

  saveMenu(menu) {
    return this.httpClient
      .post<Menu>(this.url + 'api/SaveMenu', menu)
      .pipe(map((res) => <Menu>res));
  }

  saveMenuKeukenType(menu) {
    return this.httpClient
      .post<Menu>(this.url + 'api/SaveMenuKeukenType', menu)
      .pipe(map((res) => <Menu>res));
  }

  saveMenuItem(menuItem) {
    return this.httpClient
      .post<MenuItem>(this.url + 'api/SaveMenuItem', menuItem)
      .pipe(map((res) => <MenuItem>res));
  }

  deleteMenuItem(menuItem) {
    return this.httpClient
      .post<boolean>(this.url + `api/DeleteMenuItem`, menuItem)
      .pipe(map((res) => <boolean>res));
  }




  pasteDayMenu(menu: Menu, dayMenuToCopyFrom : string ){
    return this.httpClient
    .post<Menu>(`${this.url}api/PasteDayMenu/?dayMenuToCopyFrom=${dayMenuToCopyFrom}`, menu)
    .pipe(map((res) => <Menu>res));


  }

  pasteMenuItems(copyMenuItems: CopyMenuItems ){
    return this.httpClient
    .post<Menu>(`${this.url}api/PasteMenuItems`, copyMenuItems)
    .pipe(map((res) => <Menu>res));
  }

  getIngredientsForMenuItem(menuItemId, language) {
    return this.httpClient.get<String>(
      this.url + 'api/GetAllIngredientsFromMenu/' + menuItemId + '/' + language
    );
  }

  getUsers() {
    return this.httpClient.get<User[]>(this.url + 'api/GetUsers');
  }

  updateUser(user: User) {
    return this.httpClient
      .post<User>(this.url + 'api/updateUser', user)
      .pipe(map((res) => <User>res));
  }

  deleteUser(id: number){
    return this.httpClient
      .get<Boolean>(this.url + 'api/deleteUser/'+id);
  }

  getTodos() {
    return this.httpClient
      .get<Todo[]>(this.url + 'api/getTodos')
      .pipe(map((res) => <Todo[]>res));
  }

  getPriceConversions() {
    return this.httpClient.get<PriceConversion[]>(
      this.url + 'api/getPriceConversions'
    );
  }

/**
 * getPriceConversion - cached using sharereplay
*/

  private readonly _getPriceConversion = new Map<string, Observable<PriceConversion>>();

  getPriceConversion(price : String): Observable<PriceConversion> {
    const cacheKey = `${price}`;
    if (!this._getPriceConversion.get(cacheKey)) {
       this._getPriceConversion.set(cacheKey, this.httpClient
      .get<PriceConversion>(this.url + 'api/getPriceConversion/' + price)
      .pipe(shareReplay()));
    };
     return  this._getPriceConversion.get(cacheKey);

    // shareReplay({refCount: true, bufferSize: 1})
  }

  // getPriceConversion without sharereplay
  // getPriceConversion(price : string) {
  //   return this.httpClient.get<PriceConversion>(
  //     this.url + 'api/getPriceConversion/' + price
  //   );
  // }

  updateTodo(todo: Todo) {
    return this.httpClient
      .post<Todo>(this.url + 'api/updateTodo', todo)
      .pipe(map((res) => <Todo>res));
  }

  addTodo(todo: Todo) {
    return this.httpClient
      .post<Todo>(this.url + 'api/addTodo', todo)
      .pipe(map((res) => <Todo>res));
  }

  getVersion(){
    return this.httpClient
    .get(this.url + 'api/getVersion', {responseType: 'text'});

  }
}
