import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { WebService } from '~core/web.service';
import { Course } from '~models/course';
import { CategoryForCourse } from '~models/category-for-course';
import { Course_CategoryForCourse } from '../models/course_category-for-course';
import { Course_CourseLogo } from '../models/course-course-logo';
import { CategoryForCourseChecked } from '../viewmodels/category-for-course-checked';
import { Security } from '~auth/security';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
})
export class CoursesComponent implements OnInit {
  @Input() selectCoursesForMenuItem: boolean = false;
  @Input() setCoursesForMenu: Set<Course> = new Set<Course>();
  @Input() totalPrice: number = 0;
  @Output() setSelectedCourses = new EventEmitter<Set<Course>>();

  //code needed for pagination
  config: any;

  pageChanged(event) {
    this.config.currentPage = event;
  }

  moveToTop() {
    window.scrollTo(0, 0);
  }

  //Code needed for editing menu content
  selectedCoursesForMenu: Course[] = [];
  typeGerecht: number = 0;

  addToSelectedCourses(course: Course) {
    var maincourseExists = {};
    try {
      if (course.maincourse) {
        this.setCoursesForMenu.forEach((c) => {
          if (c.maincourse) throw maincourseExists;
        });
      }

      this.setCoursesForMenu.add(course);

      this.setSelectedCourses.emit(this.setCoursesForMenu);
      this.doFilterMetatag();
    } catch (e) {
      if (e !== maincourseExists) throw e;
      alert('u kan slechts 1 hoofdgerecht toevoegen');
    }
    this.totalPrice = 0;
    this.setCoursesForMenu.forEach((c) => (this.totalPrice += c.price));
    if (this.totalPrice > 5.61)
      alert('uw menu gaat over de maximum prijs van € 5.60');
  }
  removeCourseFromSelectedCourses(course: Course) {
    this.setCoursesForMenu.delete(course);
    this.setSelectedCourses.emit(this.setCoursesForMenu);
    this.doFilterMetatag();

    this.totalPrice = 0;
    this.setCoursesForMenu.forEach((c) => (this.totalPrice += c.price));
    if (this.totalPrice > 5.61)
      alert('uw menu gaat over de maximum prijs van € 5.60');
  }

  //end code

  //ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

  //}

  courses: Course[];
  selectedCategoryForCourses: CategoryForCourse[] = [];
  unSelectedCategoryForCourses: CategoryForCourse[] = [];
  categoryForCourses: CategoryForCourse[];

  categoryForCoursesChecked: CategoryForCourseChecked[] = [];
  filtername: string = '';

  showDeleted: boolean = false;
  showEnabled: boolean = true;

  closeResult: string;

  constructor(
    public webService: WebService,
    public security: Security,
    private modalService: NgbModal
  ) {
    this.config = {
      itemsPerPage: 100,
      currentPage: 1,
    };
  }

  ngOnInit() {
    //check if there is a local copy to use - so use it, and then start the network request.
    //this makes the loading of the page faster
    //if (localStorage.getItem("allCourses") !== null) {
    //    this.courses = JSON.parse(localStorage.getItem('allCourses'));
    //}
    //this.getFilterData();

    //re-load all the data
    this.refreshData();
  }
  onOptionsGerechtSelected() {}

  refreshData() {
    this.getFilterData();
    this.doFilterMetatag();

    this.webService.getCategoryForCourses().subscribe((data) => {
      this.categoryForCourses = data;
      this.loadCategoryForCoursesChecked();
    });
  }
  loadCategoryForCoursesChecked() {
    this.categoryForCoursesChecked = [];
    for (var obja of this.categoryForCourses) {
      let CatFCC: CategoryForCourseChecked = new CategoryForCourseChecked();
      CatFCC.id = obja.id;
      CatFCC.nameNl = obja.nameNl;
      CatFCC.deleted = obja.deleted;
      CatFCC.added = false;
      CatFCC.removed = false;
      let scfc: CategoryForCourse = this.selectedCategoryForCourses.find(
        (x) => x.id == CatFCC.id
      );
      if (scfc) CatFCC.added = true;
      let ucfc: CategoryForCourse = this.unSelectedCategoryForCourses.find(
        (x) => x.id == CatFCC.id
      );
      if (ucfc) CatFCC.removed = true;
      this.categoryForCoursesChecked.push(CatFCC);
    }
  }

  getFilterData() {
    //after loading the complete set of courses - check is there was a filter last time around

    if (localStorage.getItem('selectedCategoryForCourses') !== null) {
      this.selectedCategoryForCourses = JSON.parse(
        localStorage.getItem('selectedCategoryForCourses')
      );
    }

    if (localStorage.getItem('unSelectedCategoryForCourses') !== null) {
      this.unSelectedCategoryForCourses = JSON.parse(
        localStorage.getItem('unSelectedCategoryForCourses')
      );
    }

    if (localStorage.getItem('filtername') !== null) {
      this.filtername = JSON.parse(localStorage.getItem('filtername'));
    }

    this.doFilterMetatag();
  }

  setFilter() {
    localStorage.setItem('filtername', JSON.stringify(this.filtername));
    this.doFilterMetatag();
  }

  //Start Ng Bootstrap typeahead
  model: any;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.toString().length < 2
          ? []
          : this.categoryForCourses
              .filter(
                (v) =>
                  v.nameNl
                    .toLowerCase()
                    .indexOf(term.toString().toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  iformatter = (x: { nameNl: string }) => x.nameNl;
  rformatter = (result: CategoryForCourse) => result.nameNl;
  //End  Ng Bootstrap typeahead

  addToSelectedMetatags() {
    if (typeof this.model !== 'undefined') {
      this.selectedCategoryForCourses.push(this.model);
      this.doFilterMetatag();
      this.categoryForCoursesChecked.find(
        (x) => x.id == this.model.id
      ).added = true;
    }
  }

  addToRemovedMetatags() {
    if (typeof this.model !== 'undefined') {
      this.unSelectedCategoryForCourses.push(this.model);
      this.doFilterMetatag();
      this.categoryForCoursesChecked.find(
        (x) => x.id == this.model.id
      ).removed = true;
    }
  }

  removeFromSelectedMetatags(cfc: CategoryForCourse) {
    this.selectedCategoryForCourses.splice(
      this.selectedCategoryForCourses.indexOf(cfc),
      1
    );
    this.doFilterMetatag();
    this.categoryForCoursesChecked.find((x) => x.id == cfc.id).added = false;
  }

  removeFromRemovedMetatags(cfc: CategoryForCourse) {
    this.unSelectedCategoryForCourses.splice(
      this.unSelectedCategoryForCourses.indexOf(cfc),
      1
    );
    this.doFilterMetatag();
    this.categoryForCoursesChecked.find((x) => x.id == cfc.id).removed = false;
  }

  courseenable(course: Course, enabled: boolean) {
    if (this.security.notLoggedIn()) return;

    course.enabled = enabled;
    this.webService
      .updateCoursePLUS(course)
      .subscribe((data) => this.updateLocalCourse(data, course));
  }

  updateLocalCourse(incomingCourse: Course, course: Course) {
    //update the course in the cache
    let cs = JSON.parse(localStorage.getItem('allCourses'));
    cs[cs.findIndex((x) => x.id == incomingCourse.id)] = course;
    //TOBIG localStorage.setItem('allCourses', JSON.stringify(cs));
    this.doFilterMetatag();
  }

  coursedelete(course: Course, deleted: boolean) {
    if (this.security.notLoggedIn()) return;

    course.deleted = deleted;
    this.webService
      .updateCoursePLUS(course)
      .subscribe((data) => this.updateLocalCourse(data, course));
  }
  changeShowDeleted(deleted: boolean) {
    this.showDeleted = deleted;
    this.doFilterMetatag();
  }

  changeShowEnabled(enabled: boolean) {
    this.showEnabled = enabled;
    this.doFilterMetatag();
  }

  doFilterMetatag(){
    this.webService.getCoursesPLUS().subscribe((data) => {
      this.applyFilterMetatag(data);

    });

  }


  applyFilterMetatag(data) {

    this.courses = data;
    //TOBIG this.courses = JSON.parse(localStorage.getItem('allCourses'));
    localStorage.setItem(
      'selectedCategoryForCourses',
      JSON.stringify(this.selectedCategoryForCourses)
    );
    localStorage.setItem(
      'unSelectedCategoryForCourses',
      JSON.stringify(this.unSelectedCategoryForCourses)
    );
    let tempCourses = [];
    let selectedCourses = this.courses;
    //check if a 'naam' is used for filering. (not a cat)
    if (this.filtername && this.filtername !== '') {
      // selectedCourses = this.courses.filter(s =>  ((s.dispNameNl.toLowerCase().indexOf(this.filtername.toLowerCase())) + (s.nameNl.toLowerCase().indexOf(this.filtername.toLowerCase()))) > -2);
      selectedCourses = this.courses.filter(
        (s) =>
          s.nameNl.toLowerCase().indexOf(this.filtername.toLowerCase()) > -1
      );
    }
    this.selectedCategoryForCourses.forEach((selectedCategory) => {
      tempCourses = [];
      selectedCourses.forEach((selectedCourse) => {
        let ccfc: Course_CategoryForCourse[] =
          selectedCourse.course_CategoryForCourses;
        if (
          typeof ccfc.find(
            (s) => s.categoryForCourseId == selectedCategory.id
          ) !== 'undefined'
        )
          tempCourses.push(selectedCourse);
      });
      selectedCourses = tempCourses;
    });

    this.unSelectedCategoryForCourses.forEach((unSelectedCategory) => {
      tempCourses = [];
      selectedCourses.forEach((selectedCourse) => {
        let ccfc: Course_CategoryForCourse[] =
          selectedCourse.course_CategoryForCourses;
        if (
          typeof ccfc.find(
            (s) => s.categoryForCourseId == unSelectedCategory.id
          ) == 'undefined'
        )
          tempCourses.push(selectedCourse);
      });
      selectedCourses = tempCourses;
    });

    this.courses = selectedCourses
      .filter((x) => x.deleted == this.showDeleted)
      .filter((x) => x.enabled == this.showEnabled);

    if (this.selectCoursesForMenuItem) {


      if (this.setCoursesForMenu.size == 0) {
        this.courses = selectedCourses
          .filter((x) => x.maincourse)
          .filter((x) => x.deleted == false)
          .filter((x) => x.enabled == true);
      } else {
        //check if the maincourse is calculate multiple prices
        let extras = false;
        this.setCoursesForMenu.forEach((x) => {
          if (x.calculatedMultiplePrices == true) extras = true;
        });
        if (extras) {
          this.courses = selectedCourses.filter((x) => !x.maincourse);
        } else {
          this.courses = [];
        }
      }

    }

    this.config = {
      itemsPerPage: 100,
      currentPage: 1,
      totalItems: this.courses.length,
    };
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changeAddedCat(cfc: CategoryForCourseChecked) {
    cfc.added = !cfc.added;
    let c: CategoryForCourse = this.categoryForCourses.find(
      (x) => x.id == cfc.id
    );
    if (cfc.added) {
      if (this.selectedCategoryForCourses.find((x) => x.id == c.id)) {
        //dit is niet mogelijk mogen zijn maar je kan met deze versie van typescript niet controleren op defined
      } else {
        this.selectedCategoryForCourses.push(c);
      }
      if (cfc.removed) {
        this.unSelectedCategoryForCourses.splice(
          this.unSelectedCategoryForCourses.indexOf(c),
          1
        );
        cfc.removed = false;
      }
    } else {
      this.selectedCategoryForCourses.splice(
        this.selectedCategoryForCourses.indexOf(c),
        1
      );
    }
    this.doFilterMetatag();
  }

  changeRemovedCat(cfc: CategoryForCourseChecked) {
    cfc.removed = !cfc.removed;
    let c: CategoryForCourse = this.categoryForCourses.find(
      (x) => x.id == cfc.id
    );
    if (cfc.removed) {
      if (this.unSelectedCategoryForCourses.find((x) => x.id == c.id)) {
        //dit is niet mogelijk mogen zijn maar je kan met deze versie van typescript niet controleren op defined
      } else {
        this.unSelectedCategoryForCourses.push(c);
      }
      if (cfc.added) {
        this.selectedCategoryForCourses.splice(
          this.selectedCategoryForCourses.indexOf(c),
          1
        );
        cfc.added = false;
      }
    } else {
      this.unSelectedCategoryForCourses.splice(
        this.unSelectedCategoryForCourses.indexOf(c),
        1
      );
    }
    this.doFilterMetatag();
  }
}
