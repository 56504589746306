<div class="row" *ngIf="!selectCoursesForMenuItem">
  <div class="col-10">
    <H2>Gerechten</H2>
    <hr />
  </div>
</div>
<!-- START SELECT COURSES FOR MENUITEM -->

<div class="row hoverable" *ngFor="let courseForMenu of setCoursesForMenu">
  <div class="col-4">
    {{ courseForMenu.nameNl }}
  </div>
  <div class="col-1 small">€ {{ courseForMenu.price.toFixed(2) }}</div>
  <div class="col-2 small">
    <span *ngIf="courseForMenu.fixedprice">gerecht (zonder extra's)</span>
    <span *ngIf="courseForMenu.fixedMultiplePrices"
      >gerecht (zonder extra's)</span
    >
    <span *ngIf="courseForMenu.calculatedMultiplePrices"
      >gerecht (met extra's)</span
    >
    <span *ngIf="!courseForMenu.maincourse">extra's</span>
  </div>
  <div class="col-4">
    <app-allergen-icon
      *ngFor="let ca of courseForMenu.course_Allergens"
      [id]="ca.allergenId"
    ></app-allergen-icon>
  </div>
  <div class="col-1">
    <mat-icon (click)="removeCourseFromSelectedCourses(courseForMenu)"
      >delete</mat-icon
    >
  </div>
</div>
<div *ngIf="totalPrice > 0" class="row col-12">
  <hr style="width: 100%; border-top: 1px solid rgb(199, 199, 199)" />
</div>
<div *ngIf="totalPrice > 0" class="row">
  <div class="col-4"></div>
  <div class="col-1" *ngIf="totalPrice < 5.61">
    €{{ totalPrice.toFixed(2) }}
  </div>
  <div
    class="col-1"
    style="font-weight: bold; color: red"
    *ngIf="totalPrice > 5.61"
  >
    €{{ totalPrice.toFixed(2) }}
  </div>
</div>
<!-- END SELECT COURSES FOR MENUITEM -->

<div class="row">
  <div class="col-1"></div>
  <div class="col-4">Naam</div>
  <div class="col-4">Categorie</div>
  <div class="col-3">
    <button class="btn btn-primary" type="button" (click)="refreshData()">
      <mat-icon aria-hidden="true" style="cursor: pointer">sync</mat-icon>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-1"></div>
  <div class="col-4">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="filtername"
      (change)="setFilter()"
    />
  </div>

  <div class="col-4">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [inputFormatter]="iformatter"
        [resultFormatter]="rformatter"
        [editable]="false"
      />
      <div class="input-group-append">
        <button class="btn btn-succes" (click)="open(contentCat)">
          <mat-icon aria-hidden="true" style="cursor: pointer"
            >expand_more</mat-icon
          >
        </button>
        <button
          class="btn btn-primary"
          type="button"
          (click)="addToSelectedMetatags()"
        >
          <mat-icon aria-hidden="true" style="cursor: pointer">add</mat-icon>
        </button>
        <button
          class="btn btn-danger"
          type="button"
          (click)="addToRemovedMetatags()"
        >
          <mat-icon aria-hidden="true" style="cursor: pointer">remove</mat-icon>
        </button>
      </div>
    </div>
    <span
      class="badge badge-primary badge-pill"
      *ngFor="let sCFC of selectedCategoryForCourses"
    >
      <span Class="material-icons material-icons-small">add</span>&nbsp;{{
        sCFC.nameNl
      }}
      <span
        class="material-icons material-icons-small-button"
        (click)="removeFromSelectedMetatags(sCFC)"
        style="cursor: pointer"
        >cancel</span
      >
    </span>
    <span
      class="badge badge-danger badge-pill"
      *ngFor="let sCFC of unSelectedCategoryForCourses"
    >
      <span Class="material-icons material-icons-small">remove</span>&nbsp;{{
        sCFC.nameNl
      }}
      <span
        class="material-icons material-icons-small-button"
        (click)="removeFromRemovedMetatags(sCFC)"
        style="cursor: pointer"
        >cancel</span
      >
    </span>
  </div>
  <div class="col-2"></div>

  <div *ngIf="security.allowed('Admin')" class="col-1" style="color: #118cba">
    <ng-container *ngIf="!selectCoursesForMenuItem">
    <app-check-box
      id="change1"
      [trueIcon]="'radio_button_checked'"
      [falseIcon]="'radio_button_unchecked'"
      [checked]="showEnabled"
      (checkedChanged)="changeShowEnabled($event)"
    ></app-check-box>
    <app-check-box
      id="change2"
      [trueIcon]="'restore_from_trash'"
      [falseIcon]="'fastfood'"
      [checked]="showDeleted"
      (checkedChanged)="changeShowDeleted($event)"
    ></app-check-box></ng-container>
  </div>
</div>

<div
  class="hoverable"
  *ngFor="let course of courses | paginate: config"
  style="border-bottom: solid 1px gainsboro; padding: 8px"
>
  <div class="row">
    <div class="col-2">
      <app-course-logo-icon
        *ngFor="let ccl of course?.course_CourseLogos"
        [id]="ccl.courseLogoId"
      ></app-course-logo-icon>
    </div>
    <div class="col-3">
      <a
        [routerLink]="['/gerecht', course.id]"
        *ngIf="!selectCoursesForMenuItem"
      >
        <span
          class="badge badge-info badge-pill small"
          *ngIf="course.calculatedMultiplePrices"
          >c</span
        >
        <span
          class="badge badge-warning badge-pill small"
          *ngIf="course.fixedprice"
          >f</span
        >
        <span
          class="badge badge-primary badge-pill small"
          *ngIf="course.fixedMultiplePrices"
          >fm</span
        >
        <span
          class="badge badge-pill small badge-success"
          *ngIf="!course.maincourse"
          >x</span
        >
        {{ course.dispNameNl }}
      </a>

      <span
        *ngIf="selectCoursesForMenuItem"
        style="color: #008cba; cursor: cell"
        (click)="addToSelectedCourses(course)"
      >
        <span
          class="badge badge-info badge-pill small"
          *ngIf="course.calculatedMultiplePrices"
          >c</span
        >
        <span
          class="badge badge-warning badge-pill small"
          *ngIf="course.fixedprice"
          >f</span
        >
        <span
          class="badge badge-primary badge-pill small"
          *ngIf="course.fixedMultiplePrices"
          >fm</span
        >
        <span
          class="badge badge-pill small badge-success"
          *ngIf="!course.maincourse"
          >x</span
        >
        {{ course.dispNameNl }}
      </span>
      <span class="small"><br />{{ course.nameNl }}</span>
      <!--<span class="small"> {{course.extra}}</span>-->
    </div>
    <div class="col-1 small">€ {{ course.price.toFixed(2) }}</div>
    <div class="col-2">
      <span
        class="badge badge-primary badge-pill"
        *ngFor="let cat of course.course_CategoryForCourses"
        >{{ cat.categoryForCourse.nameNl }}
      </span>
    </div>
    <div class="col-3">
      <app-allergen-icon
        *ngFor="let al of course?.course_Allergens"
        [id]="al.allergenId"
      ></app-allergen-icon>
    </div>
    <div class="col-1" *ngIf="security.allowed('Admin')">
      <ng-container *ngIf="!selectCoursesForMenuItem">
        <app-check-box
          [trueIcon]="'radio_button_checked'"
          [falseIcon]="'radio_button_unchecked'"
          [checked]="course.enabled"
          (checkedChanged)="courseenable(course, $event)"
        ></app-check-box>
        <app-check-box
          [trueIcon]="'restore_from_trash'"
          [falseIcon]="'fastfood'"
          [checked]="course.deleted"
          (checkedChanged)="coursedelete(course, $event)"
        ></app-check-box
      ></ng-container>
    </div>
    <div *ngIf="selectCoursesForMenuItem" class="col-1"></div>
  </div>
</div>

<div class="row">
  <div class="col-lg-11" style="text-align: center">
    <pagination-controls
      (pageChange)="pageChanged($event)"
    ></pagination-controls>
  </div>
  <div class="col-lg-1" style="text-align: center">
    <i class="material-icons" (click)="moveToTop()"> arrow_upward </i>
  </div>
</div>
<ng-container  *ngIf="security.allowed('Admin,Chef')">
<div class="row" *ngIf="filtername">
  <div class="col-12" *ngIf="!selectCoursesForMenuItem">
    <a class="btn btn-primary" [routerLink]="['/course/0']">
      nieuw gerecht toevoegen</a
    >
  </div>
</div>
</ng-container>

<ng-template #contentCat let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Categories</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
      style="border: 0px;"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div
      *ngFor="let cfc of this.categoryForCoursesChecked"
      class="row hoverable"
    >
      <div class="col-12">
        <span
          class="badge badge-pill"
          [ngClass]="{
            'badge-primary': cfc.added,
            'badge-danger': cfc.removed,
            'badge-light': !cfc.added && !cfc.removed
          }"
        >
          <span Class="material-icons material-icons-small">add</span>
          <input
            style="vertical-align: middle"
            type="checkbox"
            [checked]="cfc.added"
            (click)="changeAddedCat(cfc)"
          />

          <span Class="material-icons material-icons-small">remove</span>
          <input
            style="vertical-align: middle"
            type="checkbox"
            [checked]="cfc.removed"
            (click)="changeRemovedCat(cfc)"
          />

          {{ cfc.nameNl }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
