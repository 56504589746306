import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { EtiketComponent } from './etiket/etiket.component';
import { PrintChefComponent } from './print-chef/print-chef.component';
import { PrintMenuitemsComponent } from './print-menuitems/print-menuitems.component';

const routes: Routes = [
  {
    path: 'menuitems/:naam/:datum',
    component: PrintMenuitemsComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' },
  },
  { path: 'chef/:naam/:datum', component: PrintChefComponent }
  ,
  {
    path: 'etiket/:id',
    component: EtiketComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrintRoutingModule {}
