<span
  *ngIf="!checked"
  Class="material-icons material-icons-large-button"
  (click)="set(true)"
  >{{ falseIcon }}</span
>
<span
  *ngIf="checked"
  Class="material-icons material-icons-large-button"
  (click)="set(false)"
  >{{ trueIcon }}</span
>
