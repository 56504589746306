<div *ngIf="!edit" class="row">
  <div class="col-1">
    {{ restaurant.id }}
  </div>
  <div class="col-2">
    {{ restaurant.name }}
  </div>
  <!-- <div class="col-2">
    {{ restaurant.remark }}
  </div> -->
  <div class="col-2">
    {{ getKeukenType(restaurant.keukenType) }}
  </div>
  <div class="col-2">
    <app-check-box
      id="restShowEnabled"
      [trueIcon]="'radio_button_checked'"
      [falseIcon]="'radio_button_unchecked'"
      [allowClick]="false"
      [checked]="restaurant.enabled"
    ></app-check-box>
  </div>
  <div class="col-3">
    <button (click)="changeEdit()" class="btn btn-primary">Edit</button>
  </div>
</div>

<div *ngIf="edit" class="row">
  <div class="col-1">
    {{ restaurant.id }}
  </div>
  <div class="col-2">
    <input [(ngModel)]="restaurant.name" type="text" class="form-control" />
  </div>
  <!-- <div class="col-2">
    <input [(ngModel)]="restaurant.remark" type="text" class="form-control" />
  </div> -->
  <div class="col-2">
    <mat-form-field appearance="outline"    >
      <mat-label>Selecteer een keuken</mat-label>
      <mat-select [(ngModel)]="restaurant.keukenType"  >
        <mat-option [value]='1'>Warme keuken</mat-option>
        <mat-option [value]='2'>Koude keuken</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-2">
    <app-check-box
      id="restShowEnabled2"
      [trueIcon]="'radio_button_checked'"
      [falseIcon]="'radio_button_unchecked'"
      [checked]="restaurant.enabled"
      (checkedChanged)="changeShowEnabled()"
    ></app-check-box>
  </div>

  <div class="col-3">
    <button (click)="saveEdit()" class="btn btn-success">Opslaan</button>
    <button (click)="cancelEdit()" class="btn btn-danger">Cancel</button>
  </div>
</div>
