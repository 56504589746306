import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';

//this will return true or false for a specific role, or roles
//when false when the jwt is expired, or the role isn't in the token

@Injectable({
  providedIn: 'root',
})
export class Security {
  public role: string = 'anonymous';
  public name: string = '';
  teller: number = 0;
  decodedToken: any;
  // private url: string = "";

  constructor(private router: Router, private jwtHelper: JwtHelperService) {}

  setCurrentRole() {
    let rolePath: string =
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
    let namePath: string =
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
    this.decodedToken = this.jwtHelper.decodeToken(
      localStorage.getItem('access_token')
    );
    this.role = this.decodedToken[rolePath];
    this.name = this.decodedToken[namePath];
    localStorage.setItem('role', this.role);
    localStorage.setItem('name', this.name);
  }

  allowed(allowedRoles: string): boolean {
    if (this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'))) {
      //this.router.navigate([{ outlets: { side: ['login'] } }]);
      localStorage.setItem('role', '');
    }

    if (typeof allowedRoles == 'object') {
      allowedRoles = allowedRoles + '';
    }
    //allowedRoles = allowedRoles + "";

    this.role = localStorage.getItem('role');

    if (allowedRoles.indexOf(',') < 0) {
      if (allowedRoles == this.role) return true;
    }
    let index = -1;
    index = allowedRoles.split(',').indexOf(this.role);
    if (index > -1) {
      return true;
    }

    return false;
  }

  loggedIn(): boolean {
    if (
      localStorage.getItem('access_token') &&
      !this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  notLoggedIn(): boolean {
    if (
      localStorage.getItem('access_token') &&
      this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'))
    ) {
      this.router.navigate([{ outlets: { side: ['login'] } }]);
      return true;
    } else {
      return false;
    }
  }
}
