<nav class="noprint navbar navbar-expand-md navbar-dark bg-primary fixed-top">
  <a class="navbar-brand" href="http://www.komida.be"
    ><img src="{{ url }}images/komida_nieuwlogo2.gif" heigth="40px"
    style="height:40px;"
  /></a>
  <button
    class="navbar-toggler hidden-sm-up"
    type="button"
    (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarsDefault"
    aria-controls="navbarsDefault"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    [ngbCollapse]="isNavbarCollapsed"
    class="collapse navbar-collapse"
    id="navbarsDefault"
  >
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a
          class="nav-link waves-effect waves-light"
          [routerLink]="['/kalender']"
          >Kalender</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link waves-effect waves-light"
          [routerLink]="['/calendar']"
          >Calendar</a
        >
      </li>
      <li class="nav-item">
        <a
          *ngIf="security.allowed('Admin,Chef,Keuken')"
          class="nav-link waves-effect waves-light"
          [routerLink]="['/courses']"
          >Gerechten</a
        >
      </li>
      <li class="nav-item" *ngIf="security.allowed('Admin,Chef,Keuken')">
        <a
          class="nav-link waves-effect waves-light"
          [routerLink]="['/ingredients']"
          >Ingredienten</a
        >
      </li>
      <li
        class="nav-item dropdown"
        ngbDropdown
        *ngIf="security.allowed('Admin')"
      >
        <a class="nav-link dropdown-toggle" id="id01" ngbDropdownToggle
          >Tools</a
        >
        <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
          <a class="dropdown-item" [routerLink]="['/tools/quickEditIngredients']"
            >Quick Edit Ingredients</a
          >
          <a class="dropdown-item" [routerLink]="['/tools/allergens']">Allergenen</a>
          <a class="dropdown-item" [routerLink]="['/tools/categoryforcourses']"
            >Categorieën voor gerechten</a
          >
          <a class="dropdown-item" [routerLink]="['/tools/categoryforingredients']"
            >Categorieën voor ingredieënten</a
          >
          <a class="dropdown-item" [routerLink]="['/tools/restaurants']"
            >Restaurants</a
          >
          <a class="dropdown-item" [routerLink]="['/admin/users']">Gebruikers</a>
          <a class="dropdown-item" [routerLink]="['/admin/switchrole']"
            >Verander tijdelijk van rol</a
          >
        </div>
      </li>
      <li>
        <a
          class="nav-link waves-effect waves-light"
          [routerLink]="[{ outlets: { side: ['login'] } }]"
          >Aan/Afmelden</a
        >
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right">
      <li class="nav-item" >
        <span *ngIf="security.allowed('Admin')" class="badge badge-info badge-pill">admin</span>
        <span *ngIf="security.allowed('Chef')" class="badge badge-info badge-pill">chef</span>
        <span *ngIf="security.allowed('Keuken')"class="badge badge-info badge-pill">keuken</span>
       <br/><span style="color:rgb(171, 162, 255);font-size:xx-small;">
        {{ version }}
      </span>
    </li>

    </ul>
  </div>
</nav>
