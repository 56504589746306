import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { AllergensComponent } from './allergens/allergens.component';
import { CategoryForCoursesComponent } from './category-for-courses/category-for-courses.component';
import { CategoryForIngredientsComponent } from './category-for-ingredients/category-for-ingredients.component';
import { QuickEditIngredientsComponent } from './quick-edit-ingredients/quick-edit-ingredients.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';

const routes: Routes = [
  {
    path: 'quickEditIngredients',
    component: QuickEditIngredientsComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin' },
  },
  {
    path: 'allergens',
    component: AllergensComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin' },
  },
  {
    path: 'categoryforcourses',
    component: CategoryForCoursesComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin' },
  },
  {
    path: 'categoryforingredients',
    component: CategoryForIngredientsComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin' },
  },
  {
    path: 'restaurants',
    component: RestaurantsComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToolsRoutingModule { }
