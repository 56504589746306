import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MenuItemId } from './menu-item-id.model';

export const loadMenuItemIds = createAction(
  '[MenuItemId/API] Load MenuItemIds',
  props<{ menuItemIds: MenuItemId[] }>()
);

export const addMenuItemId = createAction(
  '[MenuItemId/API] Add MenuItemId',
  props<{ menuItemId: MenuItemId }>()
);

export const upsertMenuItemId = createAction(
  '[MenuItemId/API] Upsert MenuItemId',
  props<{ menuItemId: MenuItemId }>()
);

export const addMenuItemIds = createAction(
  '[MenuItemId/API] Add MenuItemIds',
  props<{ menuItemIds: MenuItemId[] }>()
);

export const upsertMenuItemIds = createAction(
  '[MenuItemId/API] Upsert MenuItemIds',
  props<{ menuItemIds: MenuItemId[] }>()
);

export const updateMenuItemId = createAction(
  '[MenuItemId/API] Update MenuItemId',
  props<{ menuItemId: Update<MenuItemId> }>()
);

export const updateMenuItemIds = createAction(
  '[MenuItemId/API] Update MenuItemIds',
  props<{ menuItemIds: Update<MenuItemId>[] }>()
);

export const deleteMenuItemId = createAction(
  '[MenuItemId/API] Delete MenuItemId',
  props<{ id: number }>()
);

export const deleteMenuItemIds = createAction(
  '[MenuItemId/API] Delete MenuItemIds',
  props<{ ids: number[] }>()
);

export const clearMenuItemIds = createAction(
  '[MenuItemId/API] Clear MenuItemIds'
);
