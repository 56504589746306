<div class="row">
  <div class="col-6">
    <h2>{{ ing.nameNl }}</h2>
  </div>
  <div class="col-4">{{ ing.vendorCode }}</div>
  <div *ngIf="security.allowed('Admin')" class="col-2">
    <small class="btn btn-info">
      <mat-icon *ngIf="edit" (click)="SwitchEdit()">check_box</mat-icon>
      <mat-icon *ngIf="!edit" (click)="SwitchEdit()"
        >check_box_outline_blank</mat-icon
      >
      Edit {{ ing.id }}
    </small>
  </div>
</div>
<!-- allergenen -->
<div class="row">
  <div class="col-12">
    <app-allergen-icon
      *ngFor="let al of ing.ingredient_Allergens"
      [id]="al.allergenId"
    ></app-allergen-icon>
    &nbsp;&nbsp;<button
      *ngIf="edit"
      class="btn btn-primary"
      (click)="open(content)"
    >
      Allergenen aanpassen
    </button>
  </div>
</div>
<!-- metatags -->
<div class="row" *ngIf="sendId != 0">
  <div class="col-6" *ngIf="edit">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [inputFormatter]="iformatter"
        [resultFormatter]="rformatter"
        [editable]="false"
      />
      <div class="input-group-append">
        <button class="btn btn-succes" (click)="open('contentCat')">
          <mat-icon aria-hidden="true">library_add</mat-icon>
        </button>
        <button
          class="btn btn-primary"
          type="button"
          (click)="addCatToIngredient()"
        >
          <mat-icon aria-hidden="true">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-8">
    <span
      class="badge badge-primary badge-pill"
      *ngFor="let cat of ing.ingredient_CategoryForIngredients"
      style="margin-right: 5px"
    >
      {{ cat.categoryForIngredient.nameNl }}
      <span
        class="material-icons material-icons-small-button"
        *ngIf="edit"
        (click)="removeCategoryForIngredient(cat.categoryForIngredient)"
        >cancel</span
      >
    </span>
  </div>
</div>
<!-- detail -->
<hr />

<div class="row">
  <div class="col-2 lbl" style="font-weight: bold">Naam (nl)</div>
  <div class="col-10 txt" *ngIf="!edit">{{ ing.dispNameNl }}</div>
  <div class="col-10 txt" *ngIf="edit">
    <input
      type="text"
      class="form-control"
      id="dispNameNl"
      [(ngModel)]="ing.dispNameNl"
      required
    />
  </div>
</div>
<div class="row">
  <div class="col-2 lbl" style="font-weight: bold">Naam (en)</div>
  <div class="col-10 txt" *ngIf="!edit">{{ ing.dispNameEn }}</div>
  <div class="col-10 txt" *ngIf="edit">
    <input
      type="text"
      class="form-control"
      id="dispNameEn"
      [(ngModel)]="ing.dispNameEn"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 lbl" style="font-style: italic">Interne naam</div>
  <div class="col-10 txt" *ngIf="!edit">{{ ing.nameNl }}</div>
  <div class="col-10 txt" *ngIf="edit">
    <input
      type="text"
      class="form-control"
      id="nameNl"
      [(ngModel)]="ing.nameNl"
      required
    />
  </div>
</div>

<div class="row">
  <div class="col-12 lbl" style="font-weight: bold">Bevat</div>
  <div class="col-12 txt" *ngIf="!edit">{{ ing.contains }}</div>
  <div class="col-12 txt" *ngIf="edit">
    <textarea
      class="form-control"
      id="contains"
      [(ngModel)]="ing.contains"
      rows="6"
    ></textarea>
  </div>
</div>
<div class="row">
  <div class="col-2" style="font-weight: bold">Leverancierscode</div>
  <div class="col-10" *ngIf="!edit">{{ ing.vendorCode }}</div>
  <div class="col-10" *ngIf="edit">
    <input
      type="text"
      class="form-control"
      id="vendorCode"
      [(ngModel)]="ing.vendorCode"
    />
  </div>
</div>

<div class="row" *ngIf="edit">
  <div class="col-12">
    <button type="button" class="btn btn-primary" (click)="Save()">
      Opslaan
    </button>
    <button type="button" class="btn btn-danger" (click)="Stop()">
      Stoppen
    </button>
  </div>
</div>
<hr />

<div class="row">
  <div class="col-12">
    <b>Dit ingredient wordt gebruikt in:</b>
    <ul>
      <li *ngFor="let cui of coursesUsingIngredient">
        <a [routerLink]="['/gerecht', cui.id]">{{ cui.dispNameNl }}</a>
      </li>
    </ul>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Allergenen</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let al of allergenschecked" class="row hoverable">
      <div class="col-2">
        <input
          type="checkbox"
          [checked]="al.checked"
          (click)="changeAllergen(al)"
        />
      </div>
      <div class="col-4">
        <app-allergen-icon [id]="al.id"></app-allergen-icon>
      </div>
      <div class="col-6">{{ al.nameNl }}</div>
    </div>
  </div>
</ng-template>
