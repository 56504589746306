import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Security } from '~auth/security';
import { Allergen } from '~models/allergen';
import { Course } from '~models/course';
import { WebService } from '~core/web.service';
import { PriceConversion } from '~models/price-conversion';
import { jsPDF } from 'jspdf';
import { ConfigService } from '~core/config.service';
import { AllergenTextComponent } from '~shared/allergen-text/allergen-text.component';

@Component({
  selector: 'app-etiket',
  templateUrl: './etiket.component.html',
  styleUrls: ['./etiket.component.css'],
})
export class EtiketComponent implements OnInit {
  [x: string]: any;
  sendId: number;
  refresh: string = new Date().toLocaleTimeString();
  courseIngredientsContent: string = '';
  course: Course;
  allergens: Allergen[];
  priceConversion: PriceConversion;
  displayPrice: string = '';
  closeResult: string;

  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  constructor(
    public webService: WebService,
    private route: ActivatedRoute,
    public security: Security,
    private router: Router,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.sendId = this.route.snapshot.params['id'];
    if (this.sendId != 0) {
      this.loadData();
      this.loadCourseIngredientContent();
    }
  }

  loadData() {
    this.webService.getAllergens().subscribe((data) => (this.allergens = data));
    this.webService.getCoursePLUS(this.sendId).subscribe(
      (data) => {
        this.course = data;
        this.displayPrice = '€ ' + this.course.price.toFixed(2);
        if (
          this.course.calculatedMultiplePrices ||
          this.course.fixedMultiplePrices
        ) {
          this.webService
            .getPriceConversion(this.course.price + '')
            .subscribe((data) => {
              this.priceConversion = data;
              this.displayPrice +=
                ' / €' + String(this.priceConversion.staffprice.toFixed(2));
            });
        }
      },
      () => console.log('ok')
    );
  }

  loadCourseIngredientContent() {
    //this.webService.getIngredientsForMenuItem(this.sendId).subscribe(
    //  data => { this.courseIngredientsContent = data.toString(); })

    this.webService
      .getIngredientContentFromCourse(this.sendId)
      .subscribe((data) => {
        this.courseIngredientsContent = data.toString();
      });
  }

  downloadAsPDF() {

    var pointToMm : number = 0.4;
    var titleFontSize: number = 8;
    var allergenFontSize: number = 6;
    var textFontsize: number=5;
    var pageWidth = 50;
    var pageHeight = 102;
    var printWidth = 44;
    var printHeight = 100;
    var vStartposition : number = 5;
    var hStartposition : number = 4;

  const doc = new jsPDF('p', 'mm', [pageHeight, pageWidth]);
    //Title
    doc.setFontSize(titleFontSize);
    let splitTitle = doc.splitTextToSize(this.course.dispNameNl, printWidth);
    doc.text(splitTitle, hStartposition, vStartposition);

    //allergens
    doc.setFontSize(allergenFontSize);
    doc.setFont('helvetica', '', 'bold');
    vStartposition += (splitTitle.length * pointToMm * titleFontSize );

    let url = this.configService.url();
    let _allergen = '';
    let _count = -1;

    this.course.course_Allergens.forEach((al) => {
      _count += 1;
      let tmpAl: Allergen = this.allergens.find(
        ({ id }) => id === al.allergenId
      );
      _allergen += tmpAl.nameNl + ' ';
      //set the images in the form.
      let src = url + 'images/' + tmpAl?.logo.replace('.gif', '.jpg');
      doc.addImage(src, 'JPG', hStartposition + _count * 5, vStartposition, 5, 5);
    });

    vStartposition += 7;
    let splitAllergen = doc.splitTextToSize(_allergen, printWidth);
    doc.text(splitAllergen, hStartposition, vStartposition);
    doc.setFontSize(5);
    doc.setFont('helvetica', '', 'normal');


    vStartposition += splitAllergen.length * allergenFontSize * pointToMm;
    let splitText = doc.splitTextToSize(this.courseIngredientsContent, printWidth);
    doc.text(splitText, hStartposition, vStartposition);

    vStartposition += splitText.length * textFontsize * pointToMm+1;
    doc.setFontSize(allergenFontSize);
    doc.setFont('helvetica', '', 'bold');
    doc.text(this.displayPrice, hStartposition,vStartposition);

    doc.output('dataurlnewwindow');

  }
}
