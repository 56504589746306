import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Security } from '~auth/security';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private security: Security
  ) {}
  canActivate(next: ActivatedRouteSnapshot) {
    if (
      localStorage.getItem('access_token') &&
      !this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'))
    ) {
      //check if there is a role or roles added
      //need to get the data from the routes.ts
      if (next.data.roles == undefined) return true;
      if (this.security.allowed(next.data.roles)) return true;
    }

    this.router.navigate([{ outlets: { side: ['login'] } }]);
    return false;
  }
}
