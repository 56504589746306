import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintRoutingModule } from './print-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '~shared/shared.module';
import { MaterialModule } from '~material/material.module';
import { EtiketComponent } from './etiket/etiket.component';
import { PrintChefComponent } from './print-chef/print-chef.component';
import { PrintMenuitemComponent } from './print-menuitem/print-menuitem.component';
import { PrintMenuitemsComponent } from './print-menuitems/print-menuitems.component';

@NgModule({
  declarations: [
    EtiketComponent,
    PrintChefComponent,
    PrintMenuitemComponent,
    PrintMenuitemsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PrintRoutingModule,
    SharedModule,
    MaterialModule,
  ],
})
export class PrintModule {}
