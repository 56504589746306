import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToolsRoutingModule } from './tools-routing.module';
import { AllergensComponent } from './allergens/allergens.component';
import { CategoryForCourse } from '../models/category-for-course';
import { CategoryForCoursesComponent } from './category-for-courses/category-for-courses.component';
import { CategoryForIngredientsComponent } from './category-for-ingredients/category-for-ingredients.component';
import { QuickEditIngredientsComponent } from './quick-edit-ingredients/quick-edit-ingredients.component';
import { QuickEditIngredientsDetailComponent } from './quick-edit-ingredients-detail/quick-edit-ingredients-detail.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { SharedModule } from '~shared/shared.module';
import { MaterialModule } from '~material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AllergensComponent,
    CategoryForCoursesComponent,
    CategoryForIngredientsComponent,
    QuickEditIngredientsComponent,
    QuickEditIngredientsDetailComponent,
    RestaurantComponent,
    RestaurantsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedModule,
    ToolsRoutingModule,
  ],
})
export class ToolsModule {}
