import { Component, OnInit, Input } from '@angular/core';
import { WebService } from '../../core/web.service';
import { CategoryForCourse } from '../../models/category-for-course';
import { Security } from '~auth/security';

@Component({
  selector: 'app-category-for-courses',
  templateUrl: './category-for-courses.component.html',
  styleUrls: ['./category-for-courses.component.css'],
})
export class CategoryForCoursesComponent implements OnInit {
  @Input() type: string;
  categories: CategoryForCourse[];
  category: CategoryForCourse;
  newcat: CategoryForCourse = new CategoryForCourse();

  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.webService
      .getCategoryForCourses()
      .subscribe((data) => (this.categories = data));
    this.newcat.nameEn = '';
    this.newcat.nameNl = '';
  }

  changeDeleted(category: CategoryForCourse, deleted: boolean) {
    if (this.security.notLoggedIn()) return;

    category.deleted = deleted;
    this.webService.updateCategoryForCourse(category).subscribe();
  }

  SaveCategory(category) {
    if (this.security.notLoggedIn()) return;

    this.webService.updateCategoryForCourse(category).subscribe();
  }

  AddCategory() {
    if (this.security.notLoggedIn()) return;

    let category: CategoryForCourse = new CategoryForCourse();
    category.deleted = false;
    category.nameEn = this.newcat.nameEn;
    category.nameNl = this.newcat.nameNl;
    this.webService
      .addCategoryForCourse(category)
      .subscribe((data) => this.loadData());
  }
}
