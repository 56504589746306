<div class="row">
  <div class="col-12">
    <H2>Gebruikers</H2>
    <hr />
  </div>
</div>
<div
  class="hoverable"
  *ngFor="let user of users"

>
  <app-user [user]="user"></app-user>
</div>
<div class="row">
  <div class="col-1"></div>
  <div class="col-2">
    <input [(ngModel)]="newuser.userName" type="text" class="form-control" />
  </div>
  <div class="col-3">
    <input [(ngModel)]="newuser.fullName" type="text" class="form-control" />
  </div>
  <div class="col-3">
    <input [(ngModel)]="newuser.email" type="text" class="form-control" />
  </div>
  <div class="col-1">
    <select [(ngModel)]="newuser.role" class="form-control">
      <option value="keuken">keuken</option>
      <option value="chef">chef</option>
      <option value="admin">admin</option>
    </select>
  </div>
  <div class="col-1">
    <button (click)="addNew()" class="btn btn-success">Nieuwe toevoegen</button>
  </div>
</div>
