import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import { CourseComposition } from '~models/course-composition';
import { Allergen } from '~models/allergen';
import { Course } from '~models/course';
import { WebService } from '~core/web.service';
import { Ingredient } from '~models/ingredient';
import { CourseCompositionVm } from '~models/course-composition-vm';
import { Observable } from 'rxjs';

import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { Security } from '~auth/security';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-course-composition',
  templateUrl: './course-composition.component.html',
  styleUrls: ['./course-composition.component.css'],
})
export class CourseCompositionComponent implements OnInit {
  @Input() ccid: number;
  @Input() level: number;
  @Input() edit: boolean = false;

  @Output() refreshAllergens = new EventEmitter();

  course: Course;
  courses: Course[];

  ingredient: Ingredient;
  ingredients: Ingredient[];

  filteredCourses: Observable<Course[]>;
  fcSelectCourses = new FormControl();

  filteredIngredients: Observable<Ingredient[]>;
  fcSelectIngredients = new FormControl();

  courseCompositionVm: CourseCompositionVm[];

  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {
    this.loadComposition();
    this.loadCourses();
    this.loadIngredients();

    this.bindCoursesValueChanges();
    this.bindIngredientsValueChanges();
  }

  ngOnChanges() {
    this.loadComposition();
  }

  refreshComposition() {
    this.loadComposition();
    this.refreshAllergens.emit('update');
  }

  //load (or reload) composition
  loadComposition() {
    this.webService.getCourseCompositionPlus(this.ccid).subscribe(
      (data) => this.setCourseCompositionPlus(data),
      () => console.log('ok')
    );
  }

  setCourseCompositionPlus(data: CourseCompositionVm[]) {
    this.courseCompositionVm = data;
  }

  //get courses out of localstorage, if not there reload from webservice
  loadCourses() {
    if (!this.courses) {
      this.webService.getCoursesPLUS().subscribe((data) => {
        this.courses = data;

      });
    }


  }

  loadIngredients() {
    if (!this.ingredients) {
      this.webService.getIngredients().subscribe((data) => {
        this.ingredients = data;
      });
    }
  }

  bindCoursesValueChanges() {
    this.filteredCourses = this.fcSelectCourses.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCourses(value))
    );
  }
  bindIngredientsValueChanges() {
    this.filteredIngredients = this.fcSelectIngredients.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterIngredients(value))
    );
  }

  private _filterCourses(value: string): Course[] {

    if (value.length > 0){
    return this.courses
      .filter(
        (v) =>
          (v.nameNl?.toLowerCase().includes(value.toLowerCase()) ||
            v.dispNameNl?.toLowerCase().includes(value.toLowerCase())) &&
          v.enabled === true &&
          v.deleted === false
      )
      .slice(0, 100);
    }else{
      return this.courses.filter((v) =>
      v.enabled === true &&
      v.deleted === false);

    }
  }

  getCourseText(course) {
    return course.nameNl;
  }

  addCourseToCourse2() {
    if (this.security.notLoggedIn()) return;

    let CC: CourseComposition = <CourseComposition>{
      id: this.ccid,
      type: 1,
      subId: this.fcSelectCourses.value.id,
      amount: '',
    };

    this.webService
      .addCourseOrIngredientToCourse(CC)
      .subscribe((data) => this.reloadContains(data));
    this.fcSelectCourses.reset(''); // removes valuechanges

    this.bindCoursesValueChanges();
    //NEW
    this.refreshComposition();
  }

  private _filterIngredients(value: string): Ingredient[] {
    const filterValueIng = value?.toLowerCase();

    return this.ingredients
      .filter(
        (v) =>
          v.nameNl?.toLowerCase().includes(filterValueIng) ||
          v.dispNameNl?.toLowerCase().includes(filterValueIng) ||
          v.vendorCode?.toLowerCase().includes(filterValueIng)
      )
      .slice(0, 100);
  }

  getIngredientText(ingredient) {
    return ingredient.nameNl;
  }

  addIngredientToCourse() {
    if (this.security.notLoggedIn()) return;

    let CC: CourseComposition = <CourseComposition>{
      id: this.ccid,
      type: 2,
      subId: this.fcSelectIngredients.value.id,
      amount: '',
    };
    this.webService
      .addCourseOrIngredientToCourse(CC)
      .subscribe((data) => this.reloadContains(data));

    this.fcSelectIngredients.reset(''); //removes valuechanges :(

    this.bindIngredientsValueChanges();
  }

  reloadContains(data: Course) {
    this.loadComposition();
    //trigger an event to reload the allergens of the main course
    this.refreshAllergens.emit('update');
  }
}
