<div
  class="hoverable"
  *ngFor="let ing of ingredients"
  style="padding: 8px; border-bottom: solid 1px gainsboro"
>
  <div class="row">
    <div class="col-4">
      <input
        type="text"
        [(ngModel)]="ing.dispNameNl"
        class="form-control"
        [ngClass]="ing.dispNameNl !== ing.nameNl ? 'changed' : ''"
        (blur)="SaveDispNameNl(ing)"
      />
    </div>
    <div class="col-4">
      {{ ing.nameNl }}
    </div>
    <div class="col-4">
      <input
        type="text"
        [(ngModel)]="ing.dispNameEn"
        class="form-control"
        (blur)="SaveDispNameNl(ing)"
      />
    </div>
  </div>
</div>
