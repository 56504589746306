<img
  *ngIf="!large"
  src="{{ url }}images/{{ allergen?.logo }}"
  ngbPopover="{{ allergenName }}"
  style="width: 32px"
  [openDelay]="300"
  [closeDelay]="500"
  triggers="mouseenter:mouseleave"
  triggers="mouseenter:mouseleave"
  containerClass="customClass"
/>
<span
  *ngIf="deletebutton"
  class="material-icons material-icons-small-button"
  style="color: red; position: relative; top: -12px; left: -8px"
  (click)="removeAllergen(id)"
  >cancel</span
>
<img
  *ngIf="large"
  src="{{ url }}images/{{ allergen?.logo }}"
  ngbPopover="{{ allergenName }}"
  style="width: 100px"
  [openDelay]="300"
  [closeDelay]="500"
  triggers="mouseenter:mouseleave"
  triggers="mouseenter:mouseleave"
  containerClass="customClass"
/>
