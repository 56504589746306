import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { CourseLogo } from '../../models/course-logo';
import { ConfigService } from '../../core/config.service';
import { WebService } from '../../core/web.service';

@Component({
  selector: 'app-course-logo-icon',
  templateUrl: './course-logo-icon.component.html',
  styleUrls: ['./course-logo-icon.component.css'],
})
export class CourseLogoIconComponent implements OnInit {
  @Input() id: number;
  @Input() deletebutton: boolean = false;
  @Input() large: boolean = false;
  @Input() language: string = 'nl';
  @Output() deleteCourseLogo = new EventEmitter();

  courseLogos: CourseLogo[];
  courseLogo: CourseLogo;
  courseLogoName: string;
  url: string = '';

  constructor(
    public webService: WebService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.webService.cachedCourseLogos.subscribe((data) =>
      this.setCourseLogos(data)
    );
    this.url = this.configService.url();

  }

  ngOnChanges() {
    this.webService.cachedCourseLogos.subscribe((data) =>
      this.setCourseLogos(data)
    );
  }
  setCourseLogos(data) {
    this.courseLogos = data;
    this.courseLogo = this.courseLogos.find((s) => s.id == this.id);
   // if (this.large)
      this.courseLogo.logo = this.courseLogo.logo.replace('.gif', '2.png');
    if (this.language == 'nl') {
      this.courseLogoName = this.courseLogo.nameNl;
    } else {
      this.courseLogoName = this.courseLogo.nameEn;
    }
  }

  removeCourseLogo(iid: number) {
    this.deleteCourseLogo.emit(iid);
  }
}
