<div id="pdfContent" #pdfContent>
  <div class="row" style="padding:0px!important">
    <div class="col-12">
      <h4>{{ course?.dispNameNl }}</h4>
    </div>
  </div>
  <div class="row" style="margin-bottom: 8px">
    <div class="col-12">
      <app-allergen-icon
        *ngFor="let al of course?.course_Allergens"
        [id]="al.allergenId"
      ></app-allergen-icon>
      <b
        ><app-allergen-text
          *ngFor="let al of course?.course_Allergens"
          [id]="al.allergenId"
        ></app-allergen-text
      ></b>
      <br />
      {{ courseIngredientsContent }}
      <br />
      <b
        ><u>{{ displayPrice }}</u></b
      >
    </div>
  </div>
</div>
<input
  type="button"
  onclick="window.print()"
  class="btn btn-warning noprint"
  value="Afdrukken - oude manier"
  style="margin-top: 50px"
/>
<input
type="button"
(click)="downloadAsPDF()"
class="btn btn-success noprint"
value="Afdrukken - als pdf"
style="margin-top: 50px"
/>

