import { MenuItem } from './menu-item';

export class Menu {
  constructor() {}
  public id: number;
  public menuDate: Date;
  public restaurantId: number;
  public keukenType: number;
  public description: string;
  public approvedById: number;
  public approvedDateTime: Date;
  public approved: boolean;
  public requestToBeApproved: boolean;
  public remark: string;
  public menuItems: MenuItem[];
}
