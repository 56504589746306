import { Course } from './course';

export class MenuItemContent {
  constructor() {}
  public id: number;
  public menuItemId: number;
  public courseId: number;
  public sortOrder: number;
  public course: Course;
}
