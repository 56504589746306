import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '~models/menu-item';
import { MenuItemContent } from '~models/menu-item-content';
import { Course } from '~models/course';
import { WebService } from '~core/web.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Security } from '~auth/security';
import { PriceConversion } from '~models/price-conversion';
import { CourseLogo } from '~models/course-logo';
import { Course_CourseLogo } from '~models/course-course-logo';

@Component({
  selector: 'app-print-menuitem',
  templateUrl: './print-menuitem.component.html',
  styleUrls: ['./print-menuitem.component.css'],
})
export class PrintMenuitemComponent implements OnInit {
  @Input() menuItem: MenuItem;
  @Input() language: string = 'NL';
  courses: Course[] = [];
  course: Course;
  price: number = 0;
  displayPrice: string = '';
  menuinfo: string = '';
  priceConversion: PriceConversion = new PriceConversion();
  coursenames: string = 'not loaded';
  ingredienten: string = 'not loaded';
  setCoursesAllergens = new Set<number>();
  setCoursesCourseLogos = new Set();
  courseLogos: CourseLogo[] = [];
  setCourses = new Set();

  constructor(
    public webService: WebService,
    private modalService: NgbModal,
    public security: Security
  ) {}

  ngOnInit() {
    this.coursenames = '';
    this.loadCourses();
  }
  loadCourses() {
    let calculatePrice: boolean = false;
    this.menuItem.menuItemContents.forEach((c) => {
      if (this.security.allowed('Admin,Chef,Keuken')) {
        if (c.course.maincourse) {
          this.menuinfo = c.course.menuInfo;
          this.coursenames =
            "<a href='/course/" +
            c.courseId +
            "'>" +
            c.course.dispNameNl.replace(/^\w/, (c) => c.toUpperCase()) +
            '</a> , ' +
            this.coursenames;
          if (c.course.calculatedMultiplePrices || c.course.fixedMultiplePrices)
            calculatePrice = true;
          this.getOrderedCourseLogos(c.course.course_CourseLogos);
        } else {
          this.coursenames +=
            "<a href='/course/" +
            c.courseId +
            "'>" +
            c.course.dispNameNl.replace(/^\w/, (c) => c.toUpperCase()) +
            '</a> , ';
        }
      } else {
        if (c.course.maincourse) {
          this.menuinfo = c.course.menuInfo;
          this.coursenames =
            c.course.dispNameNl.replace(/^\w/, (c) => c.toUpperCase()) +
            ' , ' +
            this.coursenames;
          if (c.course.calculatedMultiplePrices || c.course.fixedMultiplePrices)
            calculatePrice = true;
          this.getOrderedCourseLogos(c.course.course_CourseLogos);
        } else {
          this.coursenames +=
            c.course.dispNameNl.replace(/^\w/, (c) => c.toUpperCase()) + ' , ';
        }
      }
      for (let courseAllergen of c.course.course_Allergens) {
        this.setCoursesAllergens.add(courseAllergen.allergenId);
      }
      //only the maincourse has courselogo's -> c.course.course_CourseLogos.forEach(l => { this.setCoursesCourseLogos.add(l.courseLogoId); })
      this.setCourses.add(c.course);
      this.price = this.price + c.course.price;
    });

    this.displayPrice = '€ ' + String(this.price.toFixed(2));
    if (calculatePrice)
      this.webService.getPriceConversion(this.price+"").subscribe((data) => {
        this.priceConversion = data;
        this.displayPrice +=
          ' / €' + String(this.priceConversion.staffprice.toFixed(2));
      });

    this.coursenames = this.coursenames.slice(0, -3);
  }

  getOrderedCourseLogos(c_cl: Course_CourseLogo[]) {
    this.webService.cachedCourseLogos.subscribe((data) => {
      let courseLogos: CourseLogo[] = data;
      c_cl.forEach((c) => {
        let courseLogo: CourseLogo = new CourseLogo();
        courseLogo = courseLogos.find((s) => s.id == c.courseLogoId);
        this.courseLogos.push(courseLogo);
      });
      this.courseLogos = this.courseLogos.sort(
        (a, b) => a.sortorder - b.sortorder
      );
    });
  }
}
