import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Allergen } from '../../models/allergen';
import { WebService } from '../../core/web.service';

@Component({
  selector: 'app-allergen-text',
  templateUrl: './allergen-text.component.html',
  styleUrls: ['./allergen-text.component.css'],
})
export class AllergenTextComponent implements OnInit {
  @Input() id: number;

  allergens: Allergen[];
  allergen: Allergen;

  constructor(public webService: WebService) {}

  ngOnInit() {
    this.webService.cachedAllergens.subscribe((data) =>
      this.setAllergens(data)
    );
  }
  ngOnChanges() {
    this.webService.cachedAllergens.subscribe((data) =>
      this.setAllergens(data)
    );
  }
  setAllergens(data) {
    this.allergens = data;
    this.allergen = this.allergens.find((s) => s.id == this.id);
  }
}
