<div class="row">
  <div class="col-11">
    <h2>Categorieën - gerechten</h2>
    <hr />
  </div>
</div>
<div class="row">
  <div class="col-2">Id</div>
  <div class="col-4">Naam</div>
  <div class="col-4">Name</div>
  <div class="col-2" style="vertical-align: text-top">
    <!--<button  >toon <span class="material-icons">restore_from_trash</span></button>-->
  </div>
</div>
<div
  class="hoverable"
  *ngFor="let cat of categories"
  style="padding: 8px; border-bottom: solid 1px gainsboro"
>
  <div class="row">
    <div class="col-2">{{ cat.id }}</div>
    <div class="col-4">
      <input
        type="text"
        [(ngModel)]="cat.nameNl"
        class="form-control"
        (blur)="SaveCategory(cat)"
      />
    </div>
    <div class="col-4">
      <input
        type="text"
        [(ngModel)]="cat.nameEn"
        class="form-control"
        (blur)="SaveCategory(cat)"
      />
    </div>
    <div class="col-2">
      <app-check-box
        [trueIcon]="'restore_from_trash'"
        [falseIcon]="'delete_outline'"
        [checked]="cat.deleted"
        (checkedChanged)="changeDeleted(cat, $event)"
      ></app-check-box>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2"></div>
  <div class="col-4">
    <input type="text" [(ngModel)]="newcat.nameNl" class="form-control" />
  </div>
  <div class="col-4">
    <input type="text" [(ngModel)]="newcat.nameEn" class="form-control" />
  </div>
  <div class="col-2">
    <button class="btn" (click)="AddCategory()">Toevoegen</button>
  </div>
</div>
