import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as appActions from '../actions/app.actions';

import { mergeMap, tap, map, timeoutWith, mapTo } from 'rxjs/operators';
import { EMPTY, fromEvent, merge, Observable, of } from 'rxjs';
import { SnackBarMessage } from '~models/snackbarmessage';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private matSnackBar: MatSnackBar) {}

  public SnackbarShow$ = this.showSnackbar();
  public SnackbarHide$ = this.hideSnackbar();

  private showSnackbar(): Observable<Action> {
    let snackMessage: SnackBarMessage;
    let returnAction: Action;
    const timeoutThreshold = 10000;

    return createEffect(() =>
      this.actions$.pipe(
        ofType(appActions.showSnackbar),
        map((input) => input.snackBarMessage),
        map((snackBarMessage) => {
          // Get snackbar config and show it
          snackMessage = snackBarMessage;
          returnAction = appActions.hideSnackbar();
          const snackBarRef = this.matSnackBar.open(
            snackBarMessage.message,
            snackBarMessage.action,
            snackBarMessage.config
          );
          return snackBarRef;
        }),
        mergeMap((snakbarRef) =>
          snakbarRef.onAction().pipe(
            // Either action is used or the operation will tiemout and issue a void message.
            map(() =>
              snackMessage.actionAction
                ? snackMessage.actionAction
                : returnAction
            ),
            timeoutWith(
              snackMessage.config
                ? snackMessage.config.duration
                : timeoutThreshold,
              EMPTY
            )
          )
        ),
        map((inp) => (inp ? inp : returnAction))
      )
    );
  }

  private hideSnackbar(): Observable<Action> {
    return createEffect(
      () =>
        this.actions$.pipe(
          ofType(appActions.hideSnackbar),
          tap(() => this.matSnackBar.dismiss())
        ),
      { dispatch: false }
    );
  }
}
