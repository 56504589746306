import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Restaurant } from '~models/restaurant';
import { Menu } from '~models/menu';
import { MenuItem } from '~models/menu-item';
import { MenuItemContent } from '~models/menu-item-content';
import { WebService } from '~core/web.service';
import { Course } from '~models/course';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Security } from '~auth/security';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-print-menuitems',
  templateUrl: './print-menuitems.component.html',
  styleUrls: ['./print-menuitems.component.css'],
})
export class PrintMenuitemsComponent implements OnInit {
  restaurants: Restaurant[];
  selectedRestaurantName: string = 'selecteer het restaurant';
  selectedRestaurant: Restaurant;
  sendRestaurant: string;
  menu: Menu;
  menuItems: any;
  date: string = '';

  menuItemContents: MenuItemContent[] = [];
  courses: Course[];
  coursesInMenuItem: Course[] = [];

  constructor(
    public webService: WebService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    public security: Security,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.webService.getRestaurants().subscribe((data) => {
      this.restaurants = data;
      this.sendRestaurant = this.route.snapshot.params['naam'];
      this.selectedRestaurant = this.restaurants.find(
        (x) => x.name == this.sendRestaurant
      );
      this.date = this.route.snapshot.params['datum'];
      this.loadMenu();
    });
  }

  loadMenu() {
    this.webService
      .getMenuByDate(this.selectedRestaurant.id, this.date)
      .subscribe((data) => {
        this.menu = data;
        this.menuItems = this.menu.menuItems.filter((x) => x.enabled == 1);
      });
  }
}
