import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '~models/menu-item';
import { MenuItemContent } from '~models/menu-item-content';
import { Course } from '~models/course';
import { WebService } from '~core/web.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Security } from '~auth/security';
import { PriceConversion } from '~models/price-conversion';
import { CourseLogo } from '~models/course-logo';
import { Course_CourseLogo } from '~models/course-course-logo';
import { Router } from '@angular/router';
import { Course_Allergen } from '~models/course_allergen';
import { Store, select } from '@ngrx/store';
import {
  selectAll,
  selectEntity,
  selectIds,
} from '~calendar/store/menu-item-id/menu-item-id.reducer';
import { map, filter } from 'rxjs/operators';
import { MenuItemId } from '~calendar/store/menu-item-id/menu-item-id.model';
import { deleteMenuItemId, upsertMenuItemId } from '~calendar/store/menu-item-id/menu-item-id.actions';
import { Observable } from 'rxjs';
import { isMenuItemIdSelected } from '~calendar/store/menu-item-id/menu-item-id.selector';
import { MatCheckbox } from '@angular/material/checkbox';

interface courseLink {
  name: string;
  link: string;
}
@Component({
  selector: 'app-menuitem',
  templateUrl: './menuitem.component.html',
  styleUrls: ['./menuitem.component.css'],
})
export class MenuitemComponent implements OnInit {
  @Input() menuItem: MenuItem;

  language: string = 'nl';

  @Output() deleteMenuItem = new EventEmitter<MenuItem>();

  courses: Course[] = [];
  course: Course;
  price: number = 0;
  extra: string = '';
  displayPrice: string = '';
  priceConversion: PriceConversion = new PriceConversion();
  coursenames: string = 'not loaded';
  ingredienten: string = 'not loaded';
  setCoursesAllergens = new Set<number>();
  setCoursesCourseLogos = new Set();
  courseLogos: CourseLogo[] = [];
  setCourses = new Set<Course>();
  currentName: string = '';
  currentUrl: string = '';
  ingredientButtonName: string = 'Ingrediënten';
  copyValue$: Observable<boolean>;
  cv: boolean;

  menuItemId: MenuItemId = new MenuItemId();

  courseLinks: courseLink[] = [];
  //menuItemId$ = this.store.pipe(select(selectAll));


  constructor(
    public webService: WebService,
    private modalService: NgbModal,
    public security: Security,
    private router: Router,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.coursenames = '';
    this.loadCourses();
   /* this.loadIngredienten();*/
    this.setCopyCheckbox();

    this.store.pipe(select(selectEntity, { id: this.menuItem?.id})).subscribe(x => this.menuItemId = x);


    this.copyValue$ = this.store.pipe(select(isMenuItemIdSelected(""+this.menuItem?.id)));
  }

  /**
   * load courses in this menuitem
   */
  loadCourses() {
    let calculatePrice: boolean = false;
    let courseNameToShowFirst: string = '';

    this.menuItem.menuItemContents.forEach((c) => {
      if (this.currentUrl.indexOf('calendar') > 0) {
        this.currentName = c.course.dispNameEn;
        this.language = 'en';
        this.ingredientButtonName = 'Ingredients';
      } else {
        this.currentName = c.course.dispNameNl;
        this.language = 'nl';
        this.ingredientButtonName = 'Ingrediënten';
      }
      if (c.course.showFirst) {
        if (this.security.allowed('Admin,Chef,Keuken')) {
          this.courseLinks.push( {
            name:this.currentName.replace(/^\w/, (c) => c.toUpperCase()),
            link:'/course/' + c.courseId
            });


          courseNameToShowFirst =
            "<a href='/course/" +
            c.courseId +
            "'>" +
            this.currentName.replace(/^\w/, (c) => c.toUpperCase()) +
            '</a>, ';
          if (c.course.calculatedMultiplePrices || c.course.fixedMultiplePrices)
            calculatePrice = true;
          this.getOrderedCourseLogos(c.course.course_CourseLogos);
        } else {
          courseNameToShowFirst =
            this.currentName.trim().charAt(0).toUpperCase() +
            this.currentName.trim().substr(1) +
            ', ';
        }
      } else {
        if (this.security.allowed('Admin,Chef,Keuken')) {
          if (c.course.maincourse) {
            if (this.currentUrl.indexOf('calendar') > 0) {
              this.extra = c.course.menuInfoEn;
            } else {
              this.extra = c.course.menuInfo;
            }
            this.courseLinks.unshift( {
              name:this.currentName.replace(/^\w/, (c) => c.toUpperCase()),
              link:'/course/' + c.courseId
              });

            this.coursenames =
              "<a href='/course/" +
              c.courseId +
              "'>" +
              this.currentName.replace(/^\w/, (c) => c.toUpperCase()) +
              '</a>, ' +
              this.coursenames;
            if (
              c.course.calculatedMultiplePrices ||
              c.course.fixedMultiplePrices
            )
              calculatePrice = true;
            this.getOrderedCourseLogos(c.course.course_CourseLogos);
          } else {

            this.courseLinks.push( {
              name:this.currentName.replace(/^\w/, (c) => c.toUpperCase()),
              link:'/course/' + c.courseId
              });

            this.coursenames +=
              "<a href='/course/" +
              c.courseId +
              "'>" +
              this.currentName.toLowerCase() +
              '</a>, ';
          }
        } else {
          if (c.course.maincourse) {
            if (this.currentUrl.indexOf('calendar') > 0) {
              this.extra = c.course.menuInfoEn;
            } else {
              this.extra = c.course.menuInfo;
            }
            this.coursenames =
              this.currentName.trim().charAt(0).toUpperCase() +
              this.currentName.trim().substr(1) +
              ', ' +
              this.coursenames;
            if (
              c.course.calculatedMultiplePrices ||
              c.course.fixedMultiplePrices
            )
              calculatePrice = true;
            this.getOrderedCourseLogos(c.course.course_CourseLogos);
          } else {
            this.coursenames += this.currentName.trim().toLowerCase() + ', ';
          }
        }
      }
      for (let courseAllergen of c.course.course_Allergens) {
        this.setCoursesAllergens.add(courseAllergen.allergenId);
      }
      //only the maincourse has courselogo's -> c.course.course_CourseLogos.forEach(l => { this.setCoursesCourseLogos.add(l.courseLogoId); })
      this.setCourses.add(c.course);
      this.price = this.price + c.course.price;
    });

    this.displayPrice = String(this.price.toFixed(2));
    if (calculatePrice)
      this.webService.getPriceConversion(this.price+"").subscribe((data) => {
        this.priceConversion = data;
        if (
          this.price.toFixed(2) == this.priceConversion.staffprice.toFixed(2)
        ) {
          this.displayPrice += ' / ERROR';
        } else {
          this.displayPrice +=
            ' / €' + String(this.priceConversion.staffprice.toFixed(2));
        }
      });

    if (courseNameToShowFirst) {
      this.coursenames = courseNameToShowFirst + this.coursenames.toLowerCase();
    }

    this.coursenames = this.coursenames.slice(0, -2);
  }

  getOrderedCourseLogos(c_cl: Course_CourseLogo[]) {
    this.webService.cachedCourseLogos.subscribe((data) => {
      let courseLogos: CourseLogo[] = data;
      c_cl.forEach((c) => {
        let courseLogo: CourseLogo = new CourseLogo();
        courseLogo = courseLogos.find((s) => s.id == c.courseLogoId);
        this.courseLogos.push(courseLogo);
      });
      this.courseLogos = this.courseLogos.sort(
        (a, b) => a.sortorder - b.sortorder
      );
    });
  }



  loadIngredienten() {
    this.webService
      .getIngredientsForMenuItem(this.menuItem.id, this.language)
      .subscribe((data) => (this.ingredienten = data.toString()));
  }

  /**
   * check is the id of this menuitem is in the ngrx observable menuItemId$
   * if so, set the box checked
   */
  setCopyCheckbox(): void {
    //if (this.menuItemId$.subscribe(x => x.menuItemIds = x.entities))
    // this.menuItemId$.pipe(map(items => items.filter(x => x.id === this.menuItem.id)).subscribe(
    //   res => {
    //     if (res.length > -1)this.copyValue=true;
    //   }
    // ))
  }


  /**
   * add's or removes this menuitem from the observable menuItemId$
   *
   * adding is done using upsert (so only one id is available)
   * delete is ..
   */
  setCopyOnOff(event, mcb : MatCheckbox): void {
    //stopPropagation();
    event.preventDefault();
    this.menuItemId = this.store.pipe(select(selectEntity, { id: this.menuItem?.id}))[0];
    if (mcb.checked=== true){
    //if (this.menuItemId !== undefined){
      this.store.dispatch(deleteMenuItemId({id: this.menuItem.id}))
    }else {
     var newMenuItemId  = {
        id: this.menuItem.id ,
         courses: this.coursenames ,
         alergens: [...this.setCoursesAllergens]
      }

     // put this in the store
     this.store.dispatch(upsertMenuItemId({menuItemId : newMenuItemId}));
    }




  }
  /**
   * show popup for ingredients
   */
  open(content) {
    //if ingredients is empty load ingredients
    if (this.ingredienten === 'not loaded') {
      this.loadIngredienten();
    }


    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  //editContent(contentCourses) {
  //  this.modalService.open(contentCourses)
  //}

  /**
   * open courses from coursesmodule to (de)select courses used in this menu
   *
   * @param contentCourses all courses already in this menuitem
   */
  editContent(contentCourses) {
    this.modalService
      .open(contentCourses, { windowClass: 'xlModal' })
      .result.then(
        () => {
          /* alert("closeresult"); begrijp niet waarom result verplicht is */
        },
        () => {
          this.updateTheCurrentMenuItem();
          this.setCourses = new Set<Course>();
        }
      );
  }
  /**
   * return currentMenuItem from webservice
   */
  updateTheCurrentMenuItem() {
    this.webService.getMenuItem(this.menuItem.id).subscribe((data) => {
      this.menuItem = data;
      this.price = 0;
      this.displayPrice = '';
      this.coursenames = '';
      this.setCoursesCourseLogos = new Set();
      this.setCoursesAllergens = new Set();
      this.courseLogos = [];
      this.loadCourses();
      this.loadIngredienten();
    });
  }

  updateSelectedCourses(coursesInMenuItem: Set<Course>) {
    if (this.security.notLoggedIn()) return;

    coursesInMenuItem.forEach((course) => {
      if (
        !this.menuItem.menuItemContents.find((x) => x.courseId === course.id)
      ) {
        let mic: MenuItemContent = new MenuItemContent();
        mic.menuItemId = this.menuItem.id;
        mic.courseId = course.id;
        mic.course = course;
        this.menuItem.menuItemContents.push(mic);
      }
    });

    this.menuItem.menuItemContents.forEach((mIC) => {
      let deleteThis: boolean = true;
      coursesInMenuItem.forEach((c) => {
        if (c.id === mIC.courseId) {
          deleteThis = false;
        }
      });
      if (deleteThis) {
        let index = this.menuItem.menuItemContents.findIndex(
          (x) => x.id == mIC.id
        );
        this.menuItem.menuItemContents.splice(index, 1);
      }
    });

    /**
     * update the data to the server
     */
    this.webService.saveMenuItem(this.menuItem).subscribe((data) => {
      this.menuItem = data;
      this.coursenames = '';
      this.setCourses.forEach((c) => {
        this.coursenames +=
          c.dispNameNl.replace(/^\w/, (d) => d.toUpperCase()) + ' / ';
      });
      this.coursenames = this.coursenames.slice(0, -3);
      this.loadIngredienten();
    });
  }

  removeThisMenuItem() {
    if (confirm('Ben je zeker dat je dit menuitem wil verwijderen')) {
      this.deleteMenuItem.emit(this.menuItem);
    }
  }

  onOptionsSelected() {
    if (this.security.notLoggedIn()) return;
    if (this.menuItem.sortorder == 10) {
      alert(
        'Gelieve de sorteervolgorde te zetten, x indien het niet belangrijk is.'
      );
    }
    this.webService.saveMenuItem(this.menuItem).subscribe();
  }
}

