import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '~material/material.module';
import { SharedModule } from '~shared/shared.module';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './user/user.component';
import { SwitchRoleComponent } from './switch-role/switch-role.component';


@NgModule({
  declarations: [UsersComponent,UserComponent,SwitchRoleComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class AdminModule { }
