<p>menuitem-composition works! {{ sendId }}</p>
<div class="row">
  <div class="col-12">
    <h5>Voeg gerecht toe.</h5>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="modelCourse"
        [ngbTypeahead]="searchCourse"
        [inputFormatter]="iformatterCourse"
        [resultFormatter]="rformatterCourse"
        [editable]="false"
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="button"
          (click)="addCourseToMenuItem(modelCourse)"
        >
          <mat-icon aria-hidden="true">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12">
    <span *ngFor="let c of coursesInMenuItem">{{ c.nameNl }}<br /></span>
  </div>
</div>
