import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AuthGuard } from '~auth/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from '~auth/jwt.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from '~auth/login/login.component';
import { MaterialModule } from '~material/material.module';
import { MenuComponent } from '~core/menu/menu.component';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { CalendarModule } from '~calendar/calendar.module';
import { SharedModule } from '~shared/shared.module';
import { CoursesModule } from '~courses/courses.module';
import { IngredientsModule } from '~ingredients/ingredients.module';
import { ToolsModule } from '~tools/tools.module';
import { CourseModule } from '~course/course.module';
import { IngredientModule } from '~ingredient/ingredient.module';
import { PrintModule } from '~print/print.module';
import { AdminModule } from './admin/admin.module';
import { StoreModule } from '@ngrx/store';
import { reducers , metaReducers} from '~core/store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '~core/store/effects/app.effects';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

export function getJwtToken(): string {
  return sessionStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        headerName: 'Authorization',
        tokenGetter: getJwtToken,
        authScheme: 'Bearer '
      },
    }),
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    AppRoutingModule,
    CalendarModule,
    SharedModule,
    CoursesModule,
    IngredientsModule,
    ToolsModule,
    CourseModule,
    IngredientModule,
    PrintModule,
    AdminModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,

    }),
    EffectsModule.forRoot([AppEffects]),
  ],
  providers: [
    DatePipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
