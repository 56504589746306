<!-- title + edit -->
<div class="row">
  <div class="col-4">
    <h2>
      Gerecht:
      <app-course-logo-icon
        *ngFor="let ccl of course?.course_CourseLogos"
        [id]="ccl.courseLogoId"
      ></app-course-logo-icon>
    </h2>
  </div>
  <div class="col-5">
    <h2>{{ course?.dispNameNl }}</h2>
    <h3>{{ course?.nameNl }}</h3>
  </div>
  <div class="col-2">
    <a class="btn btn-danger" [routerLink]="['/print/etiket', course.id]"
      >etiket</a
    >&nbsp;
    <input
      class="btn btn-danger noprint"
      onclick="window.print()"
      type="button"
      value="Afdrukken"
    />
  </div>
  <div *ngIf="security.allowed('Admin')" class="col-1">
    <small class="btn btn-info">
      <mat-icon *ngIf="edit" (click)="switchEdit()">check_box</mat-icon>
      <mat-icon *ngIf="!edit" (click)="switchEdit()"
        >check_box_outline_blank</mat-icon
      >
      Edit {{ sendId }}
    </small>
  </div>
</div>
<!-- allergenen -->
<div class="row" style="margin-bottom: 8px">
  <div class="col-8">
    <app-allergen-icon
      *ngFor="let al of course.course_Allergens"
      [id]="al.allergenId"
    ></app-allergen-icon>
  </div>
</div>
<!-- metatags -->
<div class="row" *ngIf="sendId != 0">
  <div class="col-6" *ngIf="edit">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [inputFormatter]="iformatter"
        [resultFormatter]="rformatter"
        [editable]="false"
      />
      <div class="input-group-append">
        <button
          class="btn btn-succes"
          (click)="open(contentCat)"
          style="cursor: pointer"
        >
          <mat-icon aria-hidden="true">library_add</mat-icon>
        </button>
        <button
          class="btn btn-primary"
          type="button"
          (click)="addCatToCourse()"
        >
          <mat-icon style="cursor: pointer" aria-hidden="true">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-8">
    <span
      class="badge badge-primary badge-pill"
      *ngFor="let cat of course.course_CategoryForCourses"
      style="margin-right: 5px"
    >
      {{ cat.categoryForCourse.nameNl }}
      <span
        class="material-icons material-icons-small-button"
        *ngIf="edit"
        (click)="removeCategoryForCourse(cat.categoryForCourse)"
        >cancel</span
      >
    </span>
  </div>
</div>
<!-- detail -->
<hr />

<div>
  <div class="row" style="margin-top: 8px">
    <div class="col-2 lbl" style="font-weight: bold">Naam (nl)</div>
    <div class="col-10 txt" *ngIf="!edit">{{ course?.dispNameNl }}</div>
    <div class="col-10 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="courseDispNameNl"
        [(ngModel)]="course.dispNameNl"
        required
      />
    </div>
  </div>

  <div class="row">
    <div class="col-2 lbl" style="font-weight: bold">Naam (en)</div>
    <div class="col-10 txt" *ngIf="!edit">{{ course?.dispNameEn }}</div>
    <div class="col-10 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="courseNameEn"
        [(ngModel)]="course.dispNameEn"
        required
      />
    </div>
  </div>

  <div class="row" style="margin-top: 8px">
    <div class="col-2 lbl" style="font-style: italic">Interne Naam (nl)</div>
    <div class="col-10 txt" *ngIf="!edit">{{ course?.nameNl }}</div>
    <div class="col-10 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="courseNameNl"
        [(ngModel)]="course.nameNl"
        required
      />
    </div>
  </div>

  <div class="row">
    <div class="col-1 lbl" style="font-weight: bold">Prijs:</div>
    <div class="col-2 txt" *ngIf="!edit">{{ course?.price }}</div>
    <div class="col-4 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="coursePrice"
        [(ngModel)]="course.price"
      />
    </div>
  </div>

  <div class="row" *ngIf="course.maincourse">
    <div class="col-2 lbl" style="font-weight: bold">
      Extra info onder prijs:
    </div>
    <div class="col-4 txt" *ngIf="!edit">{{ course?.menuInfo }}</div>
    <div class="col-4 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="courseMenuInfo"
        [(ngModel)]="course.menuInfo"
      />
    </div>
    <div class="col-1 lbl" style="font-weight: bold">Engels:</div>
    <div class="col-4 txt" *ngIf="!edit">{{ course?.menuInfoEn }}</div>
    <div class="col-4 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="courseMenuInfoEn"
        [(ngModel)]="course.menuInfoEn"
      />
    </div>
  </div>

  <div class="row" *ngIf="edit">
    <div class="col-2 lbl">
      Hoofdgerecht:

      <mat-icon
        *ngIf="course.maincourse"
        (click)="switchMainCourse()"
        style="cursor: pointer"
        >check_box</mat-icon
      >

      <mat-icon
        *ngIf="!course.maincourse"
        (click)="switchMainCourse()"
        style="cursor: pointer"
        >check_box_outline_blank</mat-icon
      >
    </div>
    <div class="col-2">
      <button
        *ngIf="course.maincourse"
        class="btn btn-primary"
        (click)="open(content)"
        style="cursor: pointer"
      >
        Gerechtikoon aanpassen
      </button>
    </div>

    <div class="col-3 lbl" *ngIf="course.maincourse">
      Meervoudige berekende prijs:

      <mat-icon
        *ngIf="course.calculatedMultiplePrices"
        (click)="switchCalculatedMultiplePrices()"
        style="cursor: pointer"
        >check_box</mat-icon
      >

      <mat-icon
        *ngIf="!course.calculatedMultiplePrices"
        (click)="switchCalculatedMultiplePrices()"
        style="cursor: pointer"
        >check_box_outline_blank</mat-icon
      >
    </div>
    <div class="col-3 lbl" *ngIf="course.maincourse">
      Vaste Meervoudige prijs:

      <mat-icon
        *ngIf="course.fixedMultiplePrices"
        (click)="switchFixedMultiplePrices()"
        style="cursor: pointer"
        >check_box</mat-icon
      >

      <mat-icon
        *ngIf="!course.fixedMultiplePrices"
        (click)="switchFixedMultiplePrices()"
        style="cursor: pointer"
        >check_box_outline_blank</mat-icon
      >
    </div>
    <div class="col-2 lbl" *ngIf="course.maincourse">
      Vaste prijs:

      <mat-icon
        *ngIf="course.fixedprice"
        (click)="switchFixedprice()"
        style="cursor: pointer"
        >check_box</mat-icon
      >

      <mat-icon
        *ngIf="!course.fixedprice"
        (click)="switchFixedprice()"
        style="cursor: pointer"
        >check_box_outline_blank</mat-icon
      >
    </div>
  </div>

  <div class="row" *ngIf="!edit">
    <div class="col-2 lbl">
      Hoofdgerecht:

      <mat-icon *ngIf="course.maincourse">check_box</mat-icon>

      <mat-icon *ngIf="!course.maincourse">check_box_outline_blank</mat-icon>
    </div>
    <div class="col-2"></div>
    <div class="col-3 lbl" *ngIf="course.maincourse">
      Meervoudige berekende prijs:
      <mat-icon *ngIf="course.calculatedMultiplePrices">check_box</mat-icon>
      <mat-icon *ngIf="!course.calculatedMultiplePrices"
        >check_box_outline_blank</mat-icon
      >
    </div>
    <div class="col-3 lbl" *ngIf="course.maincourse">
      Vaste Meervoudige prijs:
      <mat-icon *ngIf="course.fixedMultiplePrices">check_box</mat-icon>
      <mat-icon *ngIf="!course.fixedMultiplePrices"
        >check_box_outline_blank</mat-icon
      >
    </div>
    <div class="col-2 lbl" *ngIf="course.maincourse">
      Vaste prijs:
      <mat-icon *ngIf="course.fixedprice">check_box</mat-icon>
      <mat-icon *ngIf="!course.fixedprice">check_box_outline_blank</mat-icon>
    </div>
  </div>
  <div class="row" *ngIf="edit">
    <div class="col-12 lbl" style="font-weight: bold">
      Toon vooraan in menu

      <mat-icon
        *ngIf="course.showFirst"
        (click)="switchShowFirst()"
        style="cursor: pointer"
        >check_box</mat-icon
      >

      <mat-icon
        *ngIf="!course.showFirst"
        (click)="switchShowFirst()"
        style="cursor: pointer"
        >check_box_outline_blank</mat-icon
      >
    </div>
  </div>

  <div class="row" *ngIf="!edit">
    <div class="col-12 lbl" style="font-weight: bold">
      Toon vooraan in menu
      <mat-icon *ngIf="course.showFirst">check_box</mat-icon>
      <mat-icon *ngIf="!course.showFirst">check_box_outline_blank</mat-icon>
    </div>
  </div>

  <div class="row">
    <div class="col-12 lbl" style="font-weight: bold">Gewicht</div>
    <div class="col-4 txt" *ngIf="!edit">{{ course.weight }}</div>
    <div class="col-4 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="courseweight"
        [(ngModel)]="course.weight"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12 lbl" style="font-weight: bold">Bereiding</div>
    <div class="col-12 txt" *ngIf="!edit">{{ course.preparation }}</div>
    <div class="col-12 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="coursePreparation"
        [(ngModel)]="course.preparation"
      />
    </div>
  </div>
  <div class="row" style="display: none">
    <div class="col-12 lbl" style="font-weight: bold">Presentation image</div>
    <div class="col-12 txt">{{ course.photo }} - in ontwikkeling</div>
  </div>

  <div class="row">
    <div class="col-12 lbl" style="font-weight: bold">Extra</div>
    <div class="col-12 txt" *ngIf="!edit">{{ course.extra }}</div>
    <div class="col-12 txt" *ngIf="edit">
      <input
        type="text"
        class="form-control"
        id="coursePrice2"
        [(ngModel)]="course.extra"
      />
    </div>
  </div>

  <div class="row" *ngIf="edit">
    <div class="col-12">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="saveButtonClicked"
        (click)="saveButtonClicked = true; save()"
        style="cursor: pointer" >
        Opslaan
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="stop()"
        style="cursor: pointer"
      >
        Stoppen
      </button>
    </div>
  </div>
  <hr />

  <div class="row" *ngIf="sendId != 0">
    <div class="col-12 lbl" style="font-weight: bold">Bevat</div>
  </div>

  <app-course-composition
    *ngIf="sendId != 0"
    [ccid]="sendId"
    [level]="0"
    [edit]="edit"
    (refreshAllergens)="loadData()"
  >
  </app-course-composition>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Gerecht ikoon</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngFor="let cc of this.courseLogosChecked" class="row hoverable">
        <div class="col-2">
          <input
            type="checkbox"
            [checked]="cc.checked"
            (click)="changeCourseLogosChecked(cc)"
          />
        </div>
        <div class="col-4">
          <app-course-logo-icon [id]="cc.id"></app-course-logo-icon>
        </div>
        <div class="col-6">{{ cc.nameNl }}</div>
      </div>
    </div>
  </ng-template>

  <ng-template #contentCat let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Categorieën</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngFor="let cfc of this.categoryForCoursesChecked">
        <span
          style="width: 100%"
          class="badge badge-pill left-pills"
          [ngClass]="{
            'badge-primary': cfc.added,
            'badge-light': !cfc.added
          }"
        >
          <span Class="material-icons material-icons-small">add</span>
          <input
            style="vertical-align: middle"
            type="checkbox"
            [checked]="cfc.added"
            (click)="changeAddedCat(cfc)"
          />
          {{ cfc.nameNl }}
        </span>
      </ng-container>
    </div>
  </ng-template>
</div>
