import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Allergen } from '../../models/allergen';
import { ConfigService } from '../../core/config.service';
import { WebService } from '../../core/web.service';

@Component({
  selector: 'app-allergen-icon',
  templateUrl: './allergen-icon.component.html',
  styleUrls: ['./allergen-icon.component.css'],
})
export class AllergenIconComponent implements OnInit {
  @Input() id: number;
  @Input() deletebutton: boolean = false;
  @Input() language: string = 'nl';
  @Input() color: string = 'black';
  @Output() deleteallergen = new EventEmitter();
  @Input() large: boolean = false;

  allergens: Allergen[];
  allergen: Allergen;
  allergenName: string = '';
  url: string = '';

  constructor(
    public webService: WebService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.webService.cachedAllergens.subscribe((data) =>
      this.setAllergens(data)
    );
    this.url = this.configService.url();
  }

  ngOnChanges() {
    this.webService.cachedAllergens.subscribe((data) =>
      this.setAllergens(data)
    );
  }
  setAllergens(data) {
    this.allergens = data;
    this.allergen = {... this.allergens.find((s) => s.id == this.id)};
    if (this.language == 'nl') {
      this.allergenName = this.allergen.nameNl;
    } else {
      this.allergenName = this.allergen.nameEn;
    }
    if (this.color==='blue'){
    this.allergen.logo = this.allergen.logo.replace('.gif','-blue.png');
    }

    if (this.large){
      this.allergen.logo = this.allergen.logo.replace('gif', 'jpg');}
  }

  removeAllergen(iid: number) {
    this.deleteallergen.emit(iid);
  }
}
