import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-moretext',
  templateUrl: './moretext.component.html',
  styleUrls: ['./moretext.component.css'],
})
export class MoretextComponent implements OnInit {
  @Input() fulltext: string;
  @Input() extra: string;
  @Input() maxshortlength: number;
  @Input() short: boolean;

  shorttext: string = '';

  constructor() {}

  ngOnInit() {
    if (this.fulltext.length < this.maxshortlength) {
      this.shorttext = this.fulltext;
      this.extra = '';
    } else {
      this.shorttext = this.fulltext.substr(0, this.maxshortlength) + '... ';
    }
  }
  showfull() {
    this.short = false;
  }
  showshort() {
    this.short = true;
  }
}
