import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config.service';
import { Security } from '~auth/security';
import { WebService } from '~core/web.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  isNavbarCollapsed = true;
  rol: string = '';
  url: string = '';
  version: string = '';

  constructor(public security: Security,
    public configService: ConfigService,
    public webService: WebService) {}

  ngOnInit() {
    this.url = this.configService.url();
    if (localStorage.getItem('role') != 'anonymous') {
      this.rol = this.security.role;
    } else {
      this.rol = '';
    }

    this.webService.getVersion()
    .subscribe(result => this.version = result);


  }
}
