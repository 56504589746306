import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedRoutingModule } from './shared-routing.module';
import { AllergenIconComponent } from './allergen-icon/allergen-icon.component';
import { AllergenTextComponent } from './allergen-text/allergen-text.component';
import { CaretComponent } from './caret/caret.component';
import { CdkNestedTreeNode } from '@angular/cdk/tree';
import { CheckBoxComponent } from './check-box/check-box.component';
import { CourseLogoIconComponent } from './course-logo-icon/course-logo-icon.component';
import { MoretextComponent } from './moretext/moretext.component';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
    AllergenIconComponent,
    AllergenTextComponent,
    CaretComponent,
    CheckBoxComponent,
    CourseLogoIconComponent,
    MoretextComponent,
  ],
  imports: [CommonModule, SharedRoutingModule,MaterialModule, NgbModule],
  exports: [
    AllergenIconComponent,
    AllergenTextComponent,
    CaretComponent,
    CheckBoxComponent,
    CourseLogoIconComponent,
    MoretextComponent,
  ],
})
export class SharedModule {}
