export class Restaurant {
  constructor() {}
  public id: number;
  public name: string;
  public tel: string;
  public remark: string;
  public deleted: boolean;
  public enabled: boolean;
  public keukenType: number;
}
