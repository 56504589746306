import { Ingredient } from './ingredient';
import { CategoryForIngredient } from './category-for-ingredient';

export class Ingredient_CategoryForIngredient {
  constructor() {}
  public ingredientId: number;
  public categoryForIngredientId: number;
  public ingredient: Ingredient;
  public categoryForIngredient: CategoryForIngredient;
}
