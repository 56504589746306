import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { jqxCalendarComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxcalendar';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { CoursesRoutingModule } from './courses-routing.module';
import { CoursesComponent } from './courses.component';
import { SharedModule } from '~shared/shared.module';
import { MaterialModule } from '~material/material.module';

@NgModule({
  declarations: [CoursesComponent,],
  imports: [
    CommonModule,

    CoursesRoutingModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [CoursesComponent]
})
export class CoursesModule { }
