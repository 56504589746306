import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IngredientsRoutingModule } from './ingredients-routing.module';
import { IngredientsComponent } from './ingredients.component';
import { SharedModule } from '~shared/shared.module';
import { MaterialModule } from '~material/material.module';

@NgModule({
  declarations: [IngredientsComponent,],
  imports: [
    CommonModule,
    FormsModule,

    IngredientsRoutingModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [IngredientsComponent]
})
export class IngredientsModule { }
