import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { WebService } from '~core/web.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Security } from '~auth/security';
import { ActivatedRoute } from '@angular/router';
import { RootObject } from './datastructure';
import { Chefmenu } from './datastructure';
//import { Restaurant } from './datastructure';
import { Menu } from './datastructure';
import { Gerecht } from './datastructure';
import { Allergen } from './datastructure';
import { Allergens } from './datastructure';
import { Logo } from './datastructure';
import { Logos } from './datastructure';
import { Restaurant } from '~models/restaurant';

@Component({
  selector: 'app-print-chef',
  templateUrl: './print-chef.component.html',
  styleUrls: ['./print-chef.component.css'],
})
export class PrintChefComponent implements OnInit {
  restId: number = 0;
  datum: string = '';
  menuObj: RootObject;
  chefmenu: Chefmenu;
  menus: Menu[] = [];

  restaurants: Restaurant[];
  selectedRestaurantName: string = '';

  constructor(
    public webService: WebService,
    public security: Security,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.selectedRestaurantName = this.route.snapshot.params['naam'];
    this.datum = this.route.snapshot.params['datum'];
    this.webService.getRestaurants().subscribe((data) => {
      this.restaurants = data;
      this.restId = this.restaurants.find(
        (x) => x.name == this.selectedRestaurantName
      ).id;
      this.webService.getChefMenu(this.datum, this.restId).subscribe((data) => {
        this.menuObj = <RootObject>data;
        this.chefmenu = this.menuObj.chefmenu;
        this.menus = this.chefmenu.menu;
      });
    });
  }

  getDayOfWeek(euroDatum: string): string {
    let datum: string =
      euroDatum.substr(6, 4) +
      '-' +
      euroDatum.substr(3, 2) +
      '-' +
      euroDatum.substr(0, 2); //'24-09-2019'
    var dayOfWeek = new Date(datum).getDay();
    return isNaN(dayOfWeek)
      ? null
      : [
          'Zondag',
          'Maandag',
          'Dinsdag',
          'Woensdag',
          'Donderdag',
          'Vrijdag',
          'Zaterdag',
        ][dayOfWeek];
  }

  //getChefMenu(menuDate, restId) {
  //  return this.httpClient.get<Course>(this.url + 'api/GetChefMenu/' + menuDate + '/' + restId);
  //}
}
