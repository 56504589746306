<div class="row">
  <div class="col-11">
    <H2>Allergenen</H2>
  </div>
</div>
<div
  class="hoverable"
  *ngFor="let allergen of allergens"
  style="padding: 8px; border-bottom: solid 1px gainsboro"
>
  <div class="row">
    <div class="col-1">
      {{ allergen.id }}
    </div>
    <div class="col-2">
      <app-allergen-icon [id]="allergen.id"></app-allergen-icon>
    </div>
    <div class="col-4">{{ allergen.nameNl }}</div>
    <div class="col-4">{{ allergen.nameEn }}</div>
    <div class="col-5"></div>
  </div>
</div>
