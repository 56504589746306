<div class="row">
  <div class="col-12">
    <H2>Restaurants</H2>
    <hr />
  </div>
</div>
<div
  class="hoverable"
  *ngFor="let restaurant of restaurants"
  style="padding: 8px; border-bottom: solid 1px gainsboro"
>
  <app-restaurant [restaurant]="restaurant"></app-restaurant>
</div>
<div class="row">
  <div class="col-1"></div>
  <div class="col-3">
    <input [(ngModel)]="newRestaurant.name" type="text" class="form-control" />
  </div>
  <mat-form-field appearance="outline"    >
    <mat-label>Selecteer een keuken</mat-label>
    <mat-select [(ngModel)]="newRestaurant.keukenType"  >
      <mat-option [value]='1'>Warme keuken</mat-option>
      <mat-option [value]='2'>Koude keuken</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- <div class="col-5">
    <input
      [(ngModel)]="newRestaurant.remark"
      type="text"
      class="form-control"
    />
  </div> -->

  <div class="col-3">
    <button (click)="addNew()" class="btn btn-success">Toevoegen</button>
  </div>
</div>
