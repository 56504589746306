import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MenuItemId } from './menu-item-id.model';
import * as MenuItemIdActions from './menu-item-id.actions';

export const menuItemIdsFeatureKey = 'menuItemIds';

export interface MenuItemIdState extends EntityState<MenuItemId> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MenuItemId> = createEntityAdapter<MenuItemId>();

export const initialState: MenuItemIdState = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(MenuItemIdActions.addMenuItemId,
    (state, action) => adapter.addOne(action.menuItemId, state)
  ),
  on(MenuItemIdActions.upsertMenuItemId,
    (state, action) => adapter.upsertOne(action.menuItemId, state)
  ),
  on(MenuItemIdActions.addMenuItemIds,
    (state, action) => adapter.addMany(action.menuItemIds, state)
  ),
  on(MenuItemIdActions.upsertMenuItemIds,
    (state, action) => adapter.upsertMany(action.menuItemIds, state)
  ),
  on(MenuItemIdActions.updateMenuItemId,
    (state, action) => adapter.updateOne(action.menuItemId, state)
  ),
  on(MenuItemIdActions.updateMenuItemIds,
    (state, action) => adapter.updateMany(action.menuItemIds, state)
  ),
  on(MenuItemIdActions.deleteMenuItemId,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MenuItemIdActions.deleteMenuItemIds,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MenuItemIdActions.loadMenuItemIds,
    (state, action) => adapter.setAll(action.menuItemIds, state)
  ),
  on(MenuItemIdActions.clearMenuItemIds,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectEntity = createSelector(
  selectEntities,
  (entities, props) => {
    try {return entities[props.id]}
    catch { };
  }
);



// export const getIds = createSelector(
//   selectEntities,

// )

