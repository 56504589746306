<div class="row">
  <div class="col-lg-12" style="text-align: center">
    <app-course-logo-icon
      *ngFor="let item of courseLogos"
      [id]="item.id"
      [large]="true"
    ></app-course-logo-icon>
  </div>
</div>
<div class="row">
  <div class="col-lg-12" style="text-align: center">
    <span style="font-size: 64px" [innerHTML]="coursenames"></span>
  </div>
</div>
<div class="row">
  <div class="col-lg-12" style="text-align: center">
    <span style="font-size: 48px;" class="text-primary" [innerHTML]="displayPrice"></span><br />
    <span
      style="font-size: 48px"
      [innerHTML]="menuinfo"
      *ngIf="menuinfo"
    ></span>
  </div>
</div>
<div class="row">
  <div class="col-lg-12" style="text-align: center">
    <app-allergen-icon
      *ngFor="let item of setCoursesAllergens"
      [id]="item"
      [large]="true"
      [color]="'blue'"
    ></app-allergen-icon>
  </div>
</div>
<div class="newpage"></div>
