<ng-container *ngIf="!deleted">
<div *ngIf="!edit" class="row" style="padding: 8px; border-bottom: solid 1px gainsboro">
  <div class="col-1">
    {{ user.id }}
  </div>
  <div class="col-2">
    {{ user.userName }}
  </div>
  <div class="col-3">
    {{ user?.fullName }}
  </div>
  <div class="col-3">
    {{ user?.email }}
  </div>
  <div class="col-1">
    {{ user?.role }}
  </div>
  <div class="col-2">
    <button (click)="changeEdit()" class="btn btn-primary">Edit</button>
    <button (click)="deleteUser()" class="btn btn-danger">Del</button>
  </div>
</div>

<div *ngIf="edit" class="row" style="padding: 8px; border-bottom: solid 1px gainsboro">
  <div class="col-1">
    {{ user.id }}
  </div>
  <div class="col-2">
    <input [(ngModel)]="user.userName" type="text" class="form-control" />
  </div>
  <div class="col-3">
    <input [(ngModel)]="user.fullName" type="text" class="form-control" />
  </div>
  <div class="col-3">
    <input [(ngModel)]="user.email" type="text" class="form-control" />
  </div>
  <div class="col-2">
    <select [(ngModel)]="user.role" class="form-control">
      <option value="keuken">keuken</option>
      <option value="chef">chef</option>
      <option value="admin">admin</option>
    </select>
  </div>
  <div class="col-1">
    <button (click)="saveEdit()" class="btn btn-success">Opslaan</button>
    <button (click)="cancelEdit()" class="btn btn-danger">Cancel</button>
  </div>
</div>
</ng-container>
