import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Security } from '~auth/security';
import { Observable } from 'rxjs';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNull } from 'util';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Allergen } from '~models/allergen';
import { Course } from '~models/course';
import { WebService } from '../core/web.service';
import { Ingredient } from '~models/ingredient';
import { Course_CategoryForCourse } from '~models/course_category-for-course';
import { Course_Allergen } from '~models/course_allergen';
import { Course_CourseLogo } from '~models/course-course-logo';
import { CategoryForCourse } from '~models/category-for-course';
import { CourseComposition } from '~models/course-composition';
import { CourseLogoChecked } from '../viewmodels/course-logo-checked';
import { CategoryForCourseChecked } from '../viewmodels/category-for-course-checked';
import { CourseLogo } from '~models/course-logo';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
})
export class CourseComponent implements OnInit {
  sendId: number;
  refresh: string = new Date().toLocaleTimeString();
  course: Course;
  courses: Course[];
  ingredient: Ingredient;
  ingredients: Ingredient[];

  allergens: Allergen[];
  categoryForCourses: CategoryForCourse[];
  categoryForCoursesChecked: CategoryForCourseChecked[] = [];

  courseLogosChecked: CourseLogoChecked[];
  closeResult: string;

  //settingvoor app
  edit: boolean = false;
  saveButtonClicked: boolean = false;

  constructor(
    public webService: WebService,
    private route: ActivatedRoute,
    public security: Security,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    //load the curent course, and show it.
    this.sendId = this.route.snapshot.params['id'];
    if (this.sendId != 0) {
      this.loadData();
    } else {
      this.SetEmptyCourse();
      this.edit = true;
    }
    //getAllCategories to add to a course
    this.webService
      .getCategoryForCourses()
      .subscribe((data) => this.setDataCFC(data));

    //this.webService.getAllergens().subscribe(
    //  data => this.setAllergens(data));

    //load the courses
    //because normally we move from courses to the edit we can use the local storage.
    //this.loadCourses();


  }

  loadData() {
    this.webService.getCoursePLUS(this.sendId).subscribe(
      (data) => {
        this.course = data;
        this.LoadCategoryForCourses();
        this.LoadCourseLogo();
      },
      () => console.log('ok')
    );
  }

  LoadCategoryForCourses() {
    this.webService.getCategoryForCourses().subscribe((data) => {
      this.categoryForCoursesChecked = data;
      for (var obja of this.categoryForCoursesChecked) {
        obja.added = false;
      }
      for (var obj of this.course.course_CategoryForCourses) {
        let cfcc: CategoryForCourseChecked = this.categoryForCoursesChecked.find(
          (x) => x.id == obj.categoryForCourseId
        );
        if (cfcc) cfcc.added = true;
      }
      //let ic = this.courseLogosChecked;
    });
  }

  LoadCourseLogo() {
    this.webService.getCourseLogos().subscribe((data) => {
      this.courseLogosChecked = data;
      for (var obja of this.courseLogosChecked) {
        obja.checked = false;
      }
      for (var obj of this.course.course_CourseLogos) {
        let cc: CourseLogoChecked = this.courseLogosChecked.find(
          (x) => x.id == obj.courseLogoId
        );
        if (cc) cc.checked = true;
      }
      //let ic = this.courseLogosChecked;
    });
  }

  refreshData(something: string) {
    this.loadData();
  }

  setDataCFC(data) {
    this.categoryForCourses = data;
  }

  public switchEdit() {
    this.edit = !this.edit;
  }

  switchMainCourse() {
    if (this.edit) this.course.maincourse = !this.course.maincourse;
    //if (!this.course.isMaincourse) this.course.course_CourseLogos = null;
    if (!this.course.maincourse) this.course.calculatedMultiplePrices = false;
    this.course.fixedMultiplePrices = false;
    this.course.fixedprice = false;
  }

  switchCalculatedMultiplePrices() {
    if (!this.edit) return;
    this.course.calculatedMultiplePrices = !this.course
      .calculatedMultiplePrices;
    this.course.fixedMultiplePrices = false;
    this.course.fixedprice = false;
  }

  switchFixedMultiplePrices() {
    if (!this.edit) return;
    this.course.calculatedMultiplePrices = false;
    this.course.fixedMultiplePrices = !this.course.fixedMultiplePrices;
    this.course.fixedprice = false;
  }

  switchFixedprice() {
    if (!this.edit) return;
    this.course.calculatedMultiplePrices = false;
    this.course.fixedMultiplePrices = false;
    this.course.fixedprice = !this.course.fixedprice;
  }

  switchShowFirst() {
    if (!this.edit) return;
    this.course.showFirst = !this.course.showFirst;
  }

  save() {
    if (this.security.notLoggedIn()) return;



    this.edit = false;
    this.webService.updateCoursePLUS(this.course).subscribe((data) => {
      this.course = data;
      this.router.navigate(['/course/', this.course.id]);
      this.sendId = this.course.id;
      this.loadData();

      //update local saved data
      let courseInCourse = this.courses.find((x) => x.id == this.course.id);
      courseInCourse = this.course;

    });
  }

  stop() {
    this.edit = false;
    this.loadData();
  }

  delete(del: boolean) {}

  //Start Ng Bootstrap typeahead
  model: any;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.categoryForCourses
              .filter(
                (v) => v.nameNl.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  iformatter = (x: { nameNl: string }) => x.nameNl;
  rformatter = (result: CategoryForCourse) => result.nameNl;
  //End  Ng Bootstrap typeahead

  //click add categorie to product
  //check if the cat is not already added
  addCatToCourse() {
    let C_CFC: Course_CategoryForCourse = new Course_CategoryForCourse();
    C_CFC.categoryForCourseId = this.model.id;
    C_CFC.courseId = this.course.id;
    let c: CategoryForCourse = this.categoryForCourses.find(
      (x) => x.id == this.model.id
    );
    C_CFC.categoryForCourse = c;

    if (
      this.course.course_CategoryForCourses.find(
        (x) => x.categoryForCourseId == C_CFC.categoryForCourseId
      )
    ) {
    } else {
      this.course.course_CategoryForCourses.push(C_CFC);
    }
  }

  iets() {
    alert('alert');
  }

  //click remove categorie from product.
  //just remove
  removeCategoryForCourse(cfc: CategoryForCourse) {
    let c_cfc = this.course.course_CategoryForCourses.find(
      (x) => x.categoryForCourseId == cfc.id
    );
    this.course.course_CategoryForCourses.splice(
      this.course.course_CategoryForCourses.indexOf(c_cfc),
      1
    );
    let cfcc = this.categoryForCoursesChecked.find((x) => x.id == cfc.id);
    cfcc.added = false;
  }

  SetEmptyCourse() {
    let courseCats: Course_CategoryForCourse[];
    let courseAlls: Course_Allergen[];
    let CCLogos: Course_CourseLogo[];

    this.course = {
      id: 0,
      dispNameNl: '',
      dispNameEn: '',
      nameNl: '',
      nameEn: '',
      weight: '',
      extra: '',
      preparation: '',
      price: 0,
      photo: '',
      isCourse: true,
      isIngredient: true,
      course_Allergens: courseAlls,
      course_CategoryForCourses: courseCats,
      course_CourseLogos: CCLogos,
      maincourse: false,
      calculatedMultiplePrices: false,
      fixedMultiplePrices: false,
      fixedprice: false,
      deleted: false,
      enabled: true,
      showFirst: false,
      menuInfo: '',
      menuInfoEn: '',
    };
  }

  open(content) {
    if (this.sendId == 0) {
      alert('U kan slechts een ikoon toevoegen na op te slaan.');
      return;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changeCourseLogosChecked(cl: CourseLogo) {
    //find the allergen id in the ingredient_allergens
    //if found - remove
    //if not found - add
    if (this.course.course_CourseLogos.find((x) => x.courseLogoId == cl.id)) {
      //defined
      this.courseLogosChecked.find((x) => x.id == cl.id).checked = false;
      this.course.course_CourseLogos.splice(
        this.course.course_CourseLogos.indexOf(
          this.course.course_CourseLogos.find((x) => x.courseLogoId == cl.id)
        ),
        1
      );
    } else {
      //undefined
      this.courseLogosChecked.find((x) => x.id == cl.id).checked = true;
      let ccl: Course_CourseLogo = {
        courseLogoId: cl.id,
        courseId: +this.sendId,
      };
      this.course.course_CourseLogos.push(ccl);
    }
  }

  changeAddedCat(cfc: CategoryForCourseChecked) {
    cfc.added = !cfc.added;
    let C_CFC: Course_CategoryForCourse = new Course_CategoryForCourse();
    C_CFC.categoryForCourseId = cfc.id;
    C_CFC.courseId = this.course.id;
    let c: CategoryForCourse = this.categoryForCourses.find(
      (x) => x.id == cfc.id
    );
    C_CFC.categoryForCourse = c;

    if (cfc.added) {
      if (
        this.course.course_CategoryForCourses.find(
          (x) => x.categoryForCourseId == cfc.id
        )
      ) {
        //dit is niet mogelijk mogen zijn maar je kan met deze versie van typescript niet controleren op defined
      } else {
        this.course.course_CategoryForCourses.push(C_CFC);
      }
    } else {
      this.course.course_CategoryForCourses.splice(
        this.course.course_CategoryForCourses.indexOf(C_CFC),
        1
      );
    }
  }
}
