import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IngredientRoutingModule } from './ingredient-routing.module';
import { IngredientComponent } from './ingredient.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '~shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { IngredientUsedInComponent } from './ingredient-used-in/ingredient-used-in.component';

@NgModule({
  declarations: [ IngredientComponent, IngredientUsedInComponent],
  imports: [
    CommonModule,
    IngredientRoutingModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class IngredientModule { }
