import { Component, OnInit, Input } from '@angular/core';
import { WebService } from '../../core/web.service';
import { CategoryForIngredient } from '../../models/category-for-ingredient';
import { Security } from '~auth/security';

@Component({
  selector: 'app-category-for-ingredients',
  templateUrl: './category-for-ingredients.component.html',
  styleUrls: ['./category-for-ingredients.component.css'],
})
export class CategoryForIngredientsComponent implements OnInit {
  @Input() type: string;
  categories: CategoryForIngredient[];
  category: CategoryForIngredient;
  newcat: CategoryForIngredient = new CategoryForIngredient();

  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {
    this.loadData();
    this.newcat.nameEn = '';
    this.newcat.nameNl = '';
  }

  loadData() {
    this.webService
      .getCategoryForIngredients()
      .subscribe((data) => (this.categories = data));
  }

  changeDeleted(category: CategoryForIngredient, deleted: boolean) {
    if (this.security.notLoggedIn()) return;

    category.deleted = deleted;
    this.webService.updateCategoryForIngredient(category).subscribe();
  }

  SaveCategory(category) {
    if (this.security.notLoggedIn()) return;

    this.webService.updateCategoryForIngredient(category).subscribe();
  }

  AddCategory() {
    if (this.security.notLoggedIn()) return;

    let category: CategoryForIngredient = new CategoryForIngredient();
    category.deleted = false;
    category.nameEn = this.newcat.nameEn;
    category.nameNl = this.newcat.nameNl;
    this.webService
      .addCategoryForIngredient(category)
      .subscribe((data) => this.loadData());
  }
}
