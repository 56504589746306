import { Component, OnInit } from '@angular/core';
import { WebService } from '../core/web.service';
import { Observable } from 'rxjs';
import { Ingredient } from '../models/ingredient';
import { Allergen } from '../models/allergen';
import { CategoryForIngredient } from '../models/category-for-ingredient';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Ingredient_CategoryForIngredient } from '../models/ingredient_category-for-ingredient';
import { Security } from '~auth/security';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.css'],
})
export class IngredientsComponent implements OnInit {
  ingredients: Ingredient[];
  selectedCategoryForIngredients: CategoryForIngredient[] = [];
  unSelectedCategoryForIngredients: CategoryForIngredient[] = [];
  categoryForIngredients: CategoryForIngredient[];
  ingredientFiltername: string = '';
  ingredientFiltervendor: string = '';
  newIngredient: string;

  showDeleted: boolean = false;
  showEnabled: boolean = true;
  edit: boolean = false;

  //code needed for pagination
  config: any;

  pageChanged(event) {
    this.config.currentPage = event;
  }
  moveToTop() {
    window.scrollTo(0, 0);
  }

  constructor(public webService: WebService, public security: Security) {
    this.config = {
      itemsPerPage: 100,
      currentPage: 1,
    };
  }

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    //this.webService
      // .getIngredientsPLUS()
      // .subscribe((data) => this.setData(data));
    this.webService
      .getCategoryForIngredients()
      .subscribe((data) => this.setDataCFI(data));
    this.getFilterData();
    this.doFilterMetatag();
  }

  // setData(data) {
  //   this.ingredients = data;
  //   //localStorage.setItem('IngredientsPlus', JSON.stringify(this.ingredients));
  //   this.getFilterData();
  // }

  getFilterData() {
    //after loading the complete set of cngredients - check is there was a filter last time around

    if (localStorage.getItem('selectedCategoryForIngredients') !== null) {
      this.selectedCategoryForIngredients = JSON.parse(
        localStorage.getItem('selectedCategoryForIngredients')
      );
    }

    if (localStorage.getItem('unSelectedCategoryForIngredients') !== null) {
      this.unSelectedCategoryForIngredients = JSON.parse(
        localStorage.getItem('unSelectedCategoryForIngredients')
      );
    }

    if (localStorage.getItem('ingredientFiltername') !== null) {
      this.ingredientFiltername = JSON.parse(
        localStorage.getItem('ingredientFiltername')
      );
    }

    if (localStorage.getItem('ingredientFiltervendor') !== null) {
      this.ingredientFiltervendor = JSON.parse(
        localStorage.getItem('ingredientFiltervendor')
      );
    }

    this.doFilterMetatag();
  }
  setDataCFI(data) {
    this.categoryForIngredients = data;
  }

  setFilter() {
    localStorage.setItem(
      'ingredientFiltername',
      JSON.stringify(this.ingredientFiltername)
    );
    this.doFilterMetatag();
  }

  setVendorFilter() {
    localStorage.setItem(
      'ingredientFiltervendor',
      JSON.stringify(this.ingredientFiltervendor)
    );
    this.doFilterMetatag();
  }

  //Start Ng Bootstrap typeahead
  model: any;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.toString().length < 2
          ? []
          : this.categoryForIngredients
              .filter(
                (v) =>
                  v.nameNl
                    .toLowerCase()
                    .indexOf(term.toString().toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  iformatter = (x: { nameNl: string }) => x.nameNl;
  rformatter = (result: CategoryForIngredient) => result.nameNl;
  //End  Ng Bootstrap typeahead

  addToSelectedMetatags() {
    if (typeof this.model !== 'undefined') {
      this.selectedCategoryForIngredients.push(this.model);
      this.doFilterMetatag();
    }
  }

  addToRemovedMetatags() {
    if (typeof this.model !== 'undefined') {
      this.unSelectedCategoryForIngredients.push(this.model);
      this.doFilterMetatag();
    }
  }

  removeFromSelectedMetatags(cfi: CategoryForIngredient) {
    this.selectedCategoryForIngredients.splice(
      this.selectedCategoryForIngredients.indexOf(cfi),
      1
    );
    this.doFilterMetatag();
  }

  removeFromRemovedMetatags(cfi: CategoryForIngredient) {
    this.unSelectedCategoryForIngredients.splice(
      this.unSelectedCategoryForIngredients.indexOf(cfi),
      1
    );
    this.doFilterMetatag();
  }

  doFilterMetatag(){
    this.webService
    .getIngredientsPLUS()
    .subscribe((data) => this.applyFilterMetatag(data));
  }

  applyFilterMetatag(data) {
    //remove
    //this.ingredients = JSON.parse(localStorage.getItem('IngredientsPlus'));
    this.ingredients = data;
    localStorage.setItem(
      'selectedCategoryForIngredients',
      JSON.stringify(this.selectedCategoryForIngredients)
    );
    localStorage.setItem(
      'unSelectedCategoryForIngredients',
      JSON.stringify(this.unSelectedCategoryForIngredients)
    );
    let tempIngredients = [];
    let selectedIngredients = this.ingredients;
    //check if a 'naam' is used for filering. (not a cat)
    if (this.ingredientFiltername && this.ingredientFiltername !== '') {
      selectedIngredients = this.ingredients.filter(
        (s) =>
          s.dispNameNl
            .toLowerCase()
            .indexOf(this.ingredientFiltername.toLowerCase()) +
            s.nameNl
              .toLowerCase()
              .indexOf(this.ingredientFiltername.toLowerCase()) >
          -2
      );
    }

    if (this.ingredientFiltervendor && this.ingredientFiltervendor !== '') {
      selectedIngredients = selectedIngredients.filter((s) =>
        s.vendorCode
          .toLowerCase()
          .indexOf(this.ingredientFiltervendor.toLowerCase())> -1
      );
    }

    this.selectedCategoryForIngredients.forEach((selectedCategory) => {
      tempIngredients = [];
      selectedIngredients.forEach((selectedIngredient) => {
        let icfi: Ingredient_CategoryForIngredient[] =
          selectedIngredient.ingredient_CategoryForIngredients;
        if (
          typeof icfi.find(
            (s) => s.categoryForIngredientId == selectedCategory.id
          ) !== 'undefined'
        )
          tempIngredients.push(selectedIngredient);
      });
      selectedIngredients = tempIngredients;
    });

    this.unSelectedCategoryForIngredients.forEach((unSelectedCategory) => {
      tempIngredients = [];
      selectedIngredients.forEach((selectedIngredient) => {
        let ccfc: Ingredient_CategoryForIngredient[] =
          selectedIngredient.ingredient_CategoryForIngredients;
        if (
          typeof ccfc.find(
            (s) => s.categoryForIngredientId == unSelectedCategory.id
          ) == 'undefined'
        )
          tempIngredients.push(selectedIngredient);
      });
      selectedIngredients = tempIngredients;
    });

    this.ingredients = selectedIngredients
      .filter((x) => x.deleted == this.showDeleted)
      .filter((x) => x.enabled == this.showEnabled);
    this.config = {
      itemsPerPage: 100,
      currentPage: 1,
      totalItems: this.ingredients.length,
    };
  }

  addIngredient() {
    //voeg een nieuw ingredient toe en toon edit ingredient scherm
  }

  ingredientenable(ingredient: Ingredient, enabled: boolean) {
    if (this.security.notLoggedIn()) return;

    ingredient.enabled = enabled;
    this.webService
      .updateIngredient(ingredient)
      .subscribe((data) => this.updateLocalIngredient(data));
  }
  ingredientdelete(ingredient: Ingredient, deleted: boolean) {
    if (this.security.notLoggedIn()) return;

    ingredient.deleted = deleted;
    this.webService
      .updateIngredient(ingredient)
      .subscribe((data) => this.updateLocalIngredient(data));
  }

  updateLocalIngredient(incomingIngredient: Ingredient) {
    //update the course in the cache
    let is = JSON.parse(localStorage.getItem('IngredientsPlus'));
    is[is.findIndex((x) => x.id == incomingIngredient.id)] = incomingIngredient;
    // localStorage.setItem('IngredientsPlus', JSON.stringify(is));
  }

  changeShowDeleted(deleted: boolean) {
    this.showDeleted = deleted;
    this.doFilterMetatag();
  }

  changeShowEnabled(enabled: boolean) {
    this.showEnabled = enabled;
    this.doFilterMetatag();
  }
}
