import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {


  constructor() { }

  url(): string {
    //return document.getElementsByTagName('base')[0].href
    if (environment.production) {
      return document.getElementsByTagName('base')[0].href
    }
    else {
      return environment.url
    }
  }
}
