import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Security } from '~auth/security';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ConfigService } from '~core/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements AfterViewInit {
  invalidLogin: boolean;
  closeResult: string;
  content;
  showModal = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    public security: Security,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public configService: ConfigService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.showModal = true;
  }

logOut(){ this.cleanupStorage();
  this.showModal = false;

  //Allow fade out animation to play before navigating back
  setTimeout(() => this.router.navigate([{ outlets: { side: null } }]), 100);}

  onClose() {
this.logOut();
  }

  onDialogClick(event: UIEvent) {
    // Capture click on dialog and prevent it from bubbling to the modal background.
    event.stopPropagation();
    event.cancelBubble = true;
  }

  login(form: NgForm) {

    this.cleanupStorage();
    setTimeout(
      () => this.router.navigate([{ outlets: { side: ['login'] } }]),
      360000000
    );

    let credentials = JSON.stringify(form.value);
    let url = this.configService.url() + 'api/auth/login';
    this.http
      .post(url, credentials, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .subscribe(
        (response) => {
          let token = (<any>response).token;
          localStorage.setItem('access_token', token);
          this.security.setCurrentRole();
          this.invalidLogin = false;
          this.router.navigate([{ outlets: { side: null } }]);
          //this.router.navigate(["/"]);
        },
        (err) => {
          this.invalidLogin = true;
        }
      );
  }


    cleanupStorage(){
      localStorage.clear();
      // localStorage.removeItem('access_token');
      // localStorage.removeItem('role');
      // localStorage.removeItem('name');
      // this.security.role = 'anonymous';
      // this.security.name = '';

      // localStorage.removeItem('selectedCategoryForCourses');
      // localStorage.removeItem('unSelectedCategoryForCourses');

      // //check date and set summer or winter.
      var CurrentMonth = new Date().getMonth();
      var CurrentDay = new Date().getDay();

      localStorage.setItem(
        'selectedCategoryForCourses',
        '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}]'
      );
      if (CurrentDay > 10) {
        localStorage.setItem(
          'selectedCategoryForCourses',
          '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}, { "id": 1001, "nameNl": "winter", "deleted": false, "nameEn": null, "course_CategoryForCourses": null }]'
        );
        if (CurrentMonth > 2) {
          localStorage.setItem(
            'selectedCategoryForCourses',
            '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}, { "id": 1000, "nameNl": "zomer", "deleted": false, "nameEn": null, "course_CategoryForCourses": null }]'
          );
        }
        if (CurrentMonth > 9) {
          localStorage.setItem(
            'selectedCategoryForCourses',
            '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}, { "id": 1001, "nameNl": "winter", "deleted": false, "nameEn": null, "course_CategoryForCourses": null }]'
          );
        }
      } else {
        localStorage.setItem(
          'selectedCategoryForCourses',
          '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}, { "id": 1001, "nameNl": "winter", "deleted": false, "nameEn": null, "course_CategoryForCourses": null }]'
        );
        if (CurrentMonth < 3) {
          localStorage.setItem(
            'selectedCategoryForCourses',
            '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}, { "id": 1001, "nameNl": "winter", "deleted": false, "nameEn": null, "course_CategoryForCourses": null }]'
          );
        }
        if (CurrentMonth < 10) {
          localStorage.setItem(
            'selectedCategoryForCourses',
            '[{"id":1031,"nameNl":"in de kijker","deleted":false,"nameEn":null,"course_CategoryForCourses":null}, { "id": 1000, "nameNl": "zomer", "deleted": false, "nameEn": null, "course_CategoryForCourses": null }]'
          );
        }
      }

      // localStorage.removeItem('filtername');
      // localStorage.setItem(
      //   'selectedCategoryForIngredients',
      //   '[{ "id": 3001, "nameNl": "komida", "nameEn": null, "deleted": false, "ingredient_CategoryForIngredient": null }]'
      // );
      // localStorage.removeItem('unSelectedCategoryForIngredients');
      // localStorage.removeItem('ingredientFiltername');

    }
}
