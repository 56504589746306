import { Course } from './course';
import { CategoryForCourse } from './category-for-course';

export class Course_CategoryForCourse {
  constructor() {}
  public courseId: number;
  public categoryForCourseId: number;
  public course: Course;
  public categoryForCourse: CategoryForCourse;
}
