<div *ngIf="level == 0">
  <!-- Only show level 0, edit in a lower level is not allowed -->
  <div class="row" *ngIf="edit">
    <div class="col-6">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Voeg een gerecht toe</mat-label>
        <input
          type="text"
          placeholder="geef delen van de naam in"
          aria-label="Text"
          matInput
          [formControl]="fcSelectCourses"
          [matAutocomplete]="auto"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="$event.stopPropagation(); addCourseToCourse2()"
        >
          <mat-icon>add</mat-icon>
        </button>

        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="getCourseText"
        >
          <mat-option
            *ngFor="let course of filteredCourses | async"
            [value]="course"
          >
            {{ course.nameNl }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Voeg een ingredient toe</mat-label>
        <input
          type="text"
          placeholder="geef delen van de naam in"
          aria-label="Text"
          matInput
          [formControl]="fcSelectIngredients"
          [matAutocomplete]="auto2"
        />

        <button
          mat-icon-button
          matSuffix
          (click)="$event.stopPropagation(); addIngredientToCourse()"
        >
          <mat-icon>add</mat-icon>
        </button>
        <mat-autocomplete
          autoActiveFirstOption
          #auto2="matAutocomplete"
          [displayWith]="getIngredientText"
        >
          <mat-option
            *ngFor="let ingredient of filteredIngredients | async"
            [value]="ingredient"
          >
            {{ ingredient.nameNl }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div>

<div style="border-left: solid 1px silver; padding-left: 16px">
  <app-course-composition-detail
    *ngFor="let cc of courseCompositionVm"
    [ccvm]="cc"
    [level]="level"
    [edit]="edit"
    (refreshData)="refreshComposition()"
  >
  </app-course-composition-detail>
</div>
