import { createSelector } from '@ngrx/store';

export const areMenuItemsSelected = createSelector(
  state => state["menuItemIds"],
  ( menuItemIds) => {
    const allEntities = Object.values(menuItemIds.entities);
    return !!allEntities.length;
  }
);


export const isMenuItemIdSelected = (id: string) => createSelector(
  state => state["menuItemIds"],
  ( menuItemIds ) => {
    const ent = menuItemIds.entities[id];
    const result = (ent!== undefined && ent !== null);
    return result
  }
);
