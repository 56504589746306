import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CourseRoutingModule } from './course-routing.module';
import { CourseCompositionDetailComponent } from './course-composition-detail/course-composition-detail.component';
import { CourseCompositionComponent } from './course-composition/course-composition.component';
import { CourseComponent } from './course.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '~material/material.module';
import { SharedModule } from '~shared/shared.module';


@NgModule({
  declarations: [CourseComponent,
    CourseCompositionComponent,
    CourseCompositionDetailComponent,],
  imports: [
    CommonModule,
    CourseRoutingModule,
    FormsModule,
    MaterialModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class CourseModule { }
