import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css'],
})
export class CheckBoxComponent {
  @Input() checked: boolean;
  @Input() trueIcon: string;
  @Input() falseIcon: string;
  @Output() checkedChanged = new EventEmitter();
  @Input() allowClick: boolean = true;
  constructor() {}
  set(_checked: boolean) {
    if (this.allowClick) {
      this.checked = _checked;
      this.checkedChanged.emit(this.checked);
    }
  }
}
