<div
  id="backdrop"
  class="modal-backdrop fade"
  [ngClass]="{ show: showModal }"
></div>
<div
  class="modal d-block fade"
  [ngClass]="{ show: showModal }"
  (click)="onClose()"
  id="listing-dialog"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalIdLabel"
>
  <div
    class="modal-dialog"
    role="document"
    (click)="onDialogClick($event)"
    style="width: 500px"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5>Aanmelden</h5>
        <button
          type="button"
          class="close"
          (click)="onClose()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          class="form-signin"
          #loginForm="ngForm"
          (ngSubmit)="login(loginForm)"
        >
          <div class="container-fluid">
            <div *ngIf="invalidLogin" class="alert alert-danger">
              Invalid username or password.
            </div>
            <br />
            <label for="username" class="sr-only">Email address</label>
            <input
              type="email"
              id="username"
              name="username"
              ngModel
              class="form-control"
              placeholder="Gebruikersnaam"
              required
              autofocus
            />
            <br />
            <label for="password" class="sr-only">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              ngModel
              class="form-control"
              placeholder="Paswoord"
              required
            />
            <br />
            <button class="btn btn-lg btn-primary btn-block" type="submit">
              Sign in
            </button>
          </div>
        </form>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
