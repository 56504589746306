import { Component, OnInit, Input } from '@angular/core';
import { Security } from '~auth/security';
import { WebService } from '../../core/web.service';
import { User } from '../../models/user';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-switch-role',
  templateUrl: './switch-role.component.html',
  styleUrls: ['./switch-role.component.css'],
})
export class SwitchRoleComponent implements OnInit {
  constructor(
    public webService: WebService,
    public security: Security,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {}
  switchToKeuken() {
    if (this.security.notLoggedIn()) return;

    let user: User = new User();
    user.userName = this.security.name;
    let url = document.getElementsByTagName('base')[0].href + 'api/auth/keuken';
    this.http
      .post(url, user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .subscribe(
        (response) => {
          let token = (<any>response).token;
          localStorage.setItem('access_token', token);
          this.security.setCurrentRole();
          this.router.navigate(['/courses']);
          //this.router.navigate(["/"]);
        },
        (err) => {
          //this.invalidLogin = true;
        }
      );
  }

  switchToChef() {
    if (this.security.notLoggedIn()) return;

    let user: User = new User();
    user.userName = this.security.name;
    let url = document.getElementsByTagName('base')[0].href + 'api/auth/chef';
    this.http
      .post(url, user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .subscribe(
        (response) => {
          let token = (<any>response).token;
          localStorage.setItem('access_token', token);
          this.security.setCurrentRole();
          this.router.navigate(['/courses']);
          //this.router.navigate(["/"]);
        },
        (err) => {
          //this.invalidLogin = true;
        }
      );
  }
}
