<div class="row">
  <div class="col-9">
    <H2>Ingredienten</H2>
    <hr />
  </div>
  <div class="col-2">
    <button class="btn btn-primary" type="button" (click)="refreshData()">
      <mat-icon aria-hidden="true">sync</mat-icon>
    </button>
  </div>
  <div class="col-1"></div>
</div>

<div class="row">
  <div class="col-2">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="ingredientFiltervendor"
      (change)="setVendorFilter()"
    />
  </div>
  <div class="col-5">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="ingredientFiltername"
      (change)="setFilter()"
    />
  </div>

  <div class="col-4">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [inputFormatter]="iformatter"
        [resultFormatter]="rformatter"
        [editable]="false"
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="button"
          (click)="addToSelectedMetatags()"
          style="cursor: pointer"
        >
          <mat-icon aria-hidden="true">zoom_in</mat-icon>
        </button>
        <button
          class="btn btn-danger"
          type="button"
          (click)="addToRemovedMetatags()"
          style="cursor: pointer"
        >
          <mat-icon aria-hidden="true">zoom_out</mat-icon>
        </button>
      </div>
    </div>

    <span
      class="badge badge-primary badge-pill"
      *ngFor="let sCFI of selectedCategoryForIngredients"
    >
      <span Class="material-icons material-icons-small">add</span>&nbsp;{{
        sCFI.nameNl
      }}
      <span
        class="material-icons material-icons-small-button"
        (click)="removeFromSelectedMetatags(sCFI)"
        >cancel</span
      >
    </span>
    <span
      class="badge badge-danger badge-pill"
      *ngFor="let sCFI of unSelectedCategoryForIngredients"
    >
      <span Class="material-icons material-icons-small">remove</span>&nbsp;{{
        sCFI.nameNl
      }}
      <span
        class="material-icons material-icons-small-button"
        (click)="removeFromRemovedMetatags(sCFI)"
        >cancel</span
      >
    </span>
  </div>

  <div class="col-1" style="color: #118cba">
    <app-check-box *ngIf="security.allowed('Admin,Chef')"
      id="change1"
      [trueIcon]="'radio_button_checked'"
      [falseIcon]="'radio_button_unchecked'"
      [checked]="showEnabled"
      (checkedChanged)="changeShowEnabled($event)"
    ></app-check-box>
    <app-check-box *ngIf="security.allowed('Admin,Chef')"
      id="change2"
      [trueIcon]="'restore_from_trash'"
      [falseIcon]="'fastfood'"
      [checked]="showDeleted"
      (checkedChanged)="changeShowDeleted($event)"
    ></app-check-box>
  </div>
</div>

<div
  class="hoverable"
  *ngFor="let ing of ingredients | paginate: config"
  style="padding: 8px; border-bottom: solid 1px gainsboro"
>
  <div class="row">
    <div class="col-2">
      {{ ing.vendorCode }}
    </div>
    <div class="col-5">
      <div class="row" style="padding-top: 0px">
        <div class="col-12">
          <a [routerLink]="['/ingredient', ing.id]">{{ ing.dispNameNl }}</a>
        </div>
        <div class="col-12">
          <small>{{ ing.nameNl }}</small>
        </div>
      </div>
    </div>
    <div class="col-5">
      <app-allergen-icon
        *ngFor="let al of ing.ingredient_Allergens"
        [id]="al.allergenId"
      ></app-allergen-icon>
    </div>
  </div>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-5">
      <app-moretext
        [extra]="'meer'"
        [maxshortlength]="40"
        [short]="true"
        [fulltext]="ing?.contains"
      >
      </app-moretext>
    </div>
    <div class="col-4">
      <span
        class="badge badge-primary badge-pill"
        *ngFor="let cat of ing.ingredient_CategoryForIngredients"
        style="margin-right: 5px"
        >{{ cat.categoryForIngredient.nameNl }}
      </span>
    </div>
    <div class="col-1">
      <app-check-box *ngIf="security.allowed('Admin,Chef')"
        [trueIcon]="'radio_button_checked'"
        [falseIcon]="'radio_button_unchecked'"
        [checked]="ing.enabled"
        (checkedChanged)="ingredientenable(ing, $event)"
      ></app-check-box>
      <app-check-box *ngIf="security.allowed('Admin,Chef')"
        [trueIcon]="'restore_from_trash'"
        [falseIcon]="'fastfood'"
        [checked]="ing.deleted"
        (checkedChanged)="ingredientdelete(ing, $event)"
      ></app-check-box>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-11" style="text-align: center">
    <pagination-controls
      (pageChange)="pageChanged($event)"
    ></pagination-controls>
  </div>
  <div class="col-lg-1" style="text-align: center">
    <i class="material-icons" (click)="moveToTop()"> arrow_upward </i>
  </div>
</div>

<div class="row" *ngIf="ingredientFiltername">
  <div class="col-12">
    <a class="btn btn-primary" [routerLink]="['/ingredient/0']">
      nieuw ingredient toevoegen</a
    >
  </div>
</div>
