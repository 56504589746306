import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../models/user';
import { WebService } from '../../core/web.service';
import { Security } from '~auth/security';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  edit: boolean = false;
  @Input() user: User;
  originalUser: User;
  deleted: boolean = false;
  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {}
  changeEdit() {
    this.edit = true;
    this.originalUser = Object.assign({}, this.user);
  }
  cancelEdit() {
    this.user = this.originalUser;
    this.edit = false;
  }
  saveEdit() {
    if (this.security.notLoggedIn()) return;

    this.webService.updateUser(this.user).subscribe((data) => {
      this.user = data;
      this.edit = false;
    });
  }
  deleteUser() {
    if (this.security.notLoggedIn()) return;
    if (confirm('Are you sure to delete :' + this.user.fullName)) {
      this.webService.deleteUser(this.user.id).subscribe((data) => {
        this.deleted = true;
      });
    }
  }
}
