<div class="row col-12">{{ debugstring }}</div>
<div class="row">
  <div class="col-lg-12">
    <div ngbDropdown class="d-inline-block">
      <button
        class="btn btn-primary"
        id="dropdownBasic1"
        ngbDropdownToggle
        style="min-width: 220px; cursor: pointer"
      >
        {{ selectedRestaurantName }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button
          *ngFor="let rest of restaurants"
          ngbDropdownItem
          (click)="setSelectedRestaurant(rest)"
          style="cursor: pointer"
        >
          {{ rest.name }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-3 col-12 col-md-12 col-sm-12" style="min-width: 220px">
    <jqxCalendar [width]="220"
                 [height]="220"
                 [firstDayOfWeek]="1"
                 [(ngModel)]="date"
                 (onChange)="myCalendarEvent($event)"
                 (onViewChange)="myCalendarEvent($event)"
                 #myCalendar></jqxCalendar>

    Date is <strong>{{ date | date: "dd-MM-yyyy" }}</strong> <br /><br />

    <span *ngIf="security.allowed('Admin,Chef')"
          class="btn btn-primary"
          (click)="addNewMenuItem()"
          style="min-width: 220px; cursor: pointer">Voeg een menuitem toe</span>
    <br />

    <ng-container *ngIf="menu">
      <span *ngIf="security.allowed('Admin,Chef')"
            class="btn btn-primary"
            (click)="copyMenuItems()"
            style="min-width: 220px; cursor: pointer; margin-top: 4px">Select all menuitems</span><br />
    </ng-container>

    <ng-container *ngIf="canPasteMenuItems$ | async">
      <span *ngIf="security.allowed('Admin,Chef')"
            class="btn btn-primary"
            (click)="pasteMenuItems()"
            style="min-width: 220px; cursor: pointer">
        Paste menu items
      </span>
    </ng-container>

    <ng-container *ngIf="canPasteMenuItems$ | async">
      <span *ngIf="security.allowed('Admin,Chef')"
            class="btn btn-primary"
            (click)="clearMenuItems()"
            style="min-width: 220px; cursor: pointer">
        Clear menu items
      </span>
    </ng-container>

    <br />

    <a *ngIf="security.allowed('Admin,Chef') && menu?.menuItems"
       class="btn btn-danger"
       [routerLink]="[
        '/print/menuitems',
        selectedRestaurantName,
        date | date: 'yyyy-MM-dd'
      ]"
       style="min-width: 220px; cursor: pointer; color: white; margin-top: 4px">Afdruk toog</a>

    <a *ngIf="security.allowed('Admin,Chef') && menu?.menuItems"
       class="btn btn-danger"
       [routerLink]="[
        '/print/chef',
        selectedRestaurantName,
        date | date: 'yyyy-MM-dd'
      ]"
       style="min-width: 220px; cursor: pointer; color: white; margin-top: 4px">Afdruk kassa</a>

    <ng-container *ngIf="security.allowed('Admin,Chef')">
      <br /><br />
      <ng-container *ngIf="menu">
        <div class="row" style="padding-top: 0px; padding-left: 16px;">

          <mat-form-field appearance="outline">
            <mat-label>Voeg keukentype toe</mat-label>
            <mat-select [(ngModel)]="menu.keukenType"
                        (selectionChange)="changeKeuken($event)">
              <mat-option [value]='1'>Warme keuken</mat-option>
              <mat-option [value]='2'>Koude keuken</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </ng-container>
    </ng-container>

  </div>

  <div class="col-lg-9 col-12 col-md-12 col-sm-12">
  
    <div class="row" style="padding-top: 0px">
      <app-menuitem
        *ngFor="let mi of menu?.menuItems"
        [menuItem]="mi"
        (deleteMenuItem)="deleteMenuItem($event)"
        class="col-lg-4 col-12 col-md-6 col-sm-12 bordered"
        style="margin-bottom: 8px"
      ></app-menuitem>
    </div>
  </div>
</div>
