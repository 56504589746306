import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Restaurant } from '~models/restaurant';
import { Menu } from '~models/menu';
import { MenuItem } from '~models/menu-item';
import { MenuItemContent } from '~models/menu-item-content';
import { WebService } from '~core/web.service';
import { Course } from '~models/course';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Security } from '~auth/security';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { jqxCalendarComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxcalendar';
import { Lang } from '../viewmodels/lang';
import { async, Observable } from 'rxjs';
import { select, State, Store } from '@ngrx/store';
import { areMenuItemsSelected } from './store/menu-item-id/menu-item-id.selector';
import { clearMenuItemIds, upsertMenuItemId } from './store/menu-item-id/menu-item-id.actions';
import { selectEntities, selectIds } from './store/menu-item-id/menu-item-id.reducer';
import { CopyMenuItems } from '~models/copy-menu-items';
import { MenuItemId } from './store/menu-item-id/menu-item-id.model';


const toNumbers = arr => arr.map(Number);
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  restaurants: Restaurant[];
  selectedRestaurantName: string = 'selecteer het restaurant';
  selectedRestaurant: Restaurant;
  sendRestaurant: string;
  sendDate: string;
  menu: Menu;
  date: Date = new Date();
  currentUrl: string = '';
  Language: Lang = { Language: 'nl' };
  menuItemContents: MenuItemContent[] = [];
  courses: Course[];
  coursesInMenuItem: Course[] = [];

  menuItemToEdit: MenuItem;

  debugstring: String = '';

  @ViewChild('myCalendar') myCalendar: jqxCalendarComponent;

  canPasteMenuItems$: Observable<boolean>;
  allIds$ : Observable<string[] | number[]>;
  allSelected$;

  constructor(
    public webService: WebService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    public security: Security,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.webService
      .getRestaurants()
      .subscribe((data) => this.setRestaurants(data));
    // Data:  { Language: 'nl' }

    this.currentUrl = this.router.url;
    if (this.currentUrl.indexOf('calendar') > 0) {
      this.selectedRestaurantName = 'select the restaurant';
      this.Language.Language = 'en';

    }

    this.canPasteMenuItems$ = this.store.pipe(select(areMenuItemsSelected));

  }

  setRestaurants(data) {
    this.restaurants = data;
    this.restaurants = this.restaurants.filter((e) => e.enabled);

    this.sendRestaurant = this.route.snapshot.params['naam'];
    if (this.sendRestaurant != null) {
      let rest: Restaurant = this.restaurants.find(
        (x) => x.name == this.sendRestaurant
      );
      this.setSelectedRestaurant(rest);
    }

    this.sendDate = this.route.snapshot.params['datum'];
    if (this.sendDate != null) {
      if (this.sendDate == 'nu') {
        this.myCalendar.setDate(new Date());
        if (this.sendRestaurant != null) {
          this.location.replaceState(
            '/kalender/' +
              this.selectedRestaurantName +
              '/' +
              this.convertDate(new Date())
          );
        }
      } else {
        this.myCalendar.setDate(new Date(this.sendDate));
      }
    }
    this.loadMenu();
  }

  loadMenu() {
    this.webService
      .getMenuByDate(this.selectedRestaurant.id, this.convertDate(this.date))
      .subscribe((data) => {
        this.menu = data;
        if (
          !(this.security.allowed('Admin') || this.security.allowed('Chef'))
        ) {
          this.menu.menuItems = this.menu.menuItems.filter(
            (x) => x.enabled == 1
          );
        }
      });
  }

  myCalendarEvent(event: any): void {
    this.date = event.args.date;
    this.loadMenu();
    this.loadCourses();
    if (this.Language.Language == 'en') {
      this.location.replaceState(
        '/calendar/' +
          this.selectedRestaurantName +
          '/' +
          this.convertDate(this.date)
      );
    } else {
      this.location.replaceState(
        '/kalender/' +
          this.selectedRestaurantName +
          '/' +
          this.convertDate(this.date)
      );
    }
  }

  /* getCoursesPLUS only when logged in */
  loadCourses() {
    //dont load courses if not logged in
    if (this.security.notLoggedIn()) return;
    if (localStorage.getItem('allCourses') !== null) {
      this.courses = JSON.parse(localStorage.getItem('allCourses'));
    } else {
      this.webService
        .getCoursesPLUS()
        .subscribe((data) => this.setCoursesData(data));
    }
  }
  setCoursesData(data) {
    this.courses = data;
    localStorage.setItem('allCourses', JSON.stringify(this.courses));
  }
  /* end getGoursesPlus */

  setSelectedRestaurant(rest: Restaurant) {
    this.selectedRestaurantName = rest.name;
    this.selectedRestaurant = rest;
    this.loadMenu();
    if (this.Language.Language == 'en') {
      this.location.replaceState(
        '/calendar/' + rest.name + '/' + this.convertDate(this.date)
      );
    } else {
      this.location.replaceState(
        '/kalender/' + rest.name + '/' + this.convertDate(this.date)
      );
    }
  }

  addNewMenuItem() {
    //check if there is a menu created - if not create menu, menuitem, and send this to the component
    if (this.security.notLoggedIn()) return;

    if (!this.menu) {
      let menu: Menu = new Menu();
      menu.menuDate = this.date;
      menu.restaurantId = this.selectedRestaurant.id;

      this.webService.addMenu(menu).subscribe((data) => {
        menu = data;
        this.addMenuItem(menu);
      });
    } else {
      this.addMenuItem(this.menu);
    }
  }

  addMenuItem(menu: Menu) {
    if (this.security.notLoggedIn()) return;

    this.webService.addMenuItem(menu).subscribe((data) => {
      this.menu = data;
    });
  }

  deleteMenuItem(menuItem: MenuItem) {
    if (this.security.notLoggedIn()) return;

    this.webService.deleteMenuItem(menuItem).subscribe();
    this.menu.menuItems = this.menu.menuItems.filter((x) => x != menuItem);
  }

  copyMenuItems(){

    this.store.dispatch(clearMenuItemIds());
    this.menu.menuItems.forEach((item) =>
    {
      let courses: string = '';
      item.menuItemContents.forEach((c)=> courses += c.course.dispNameNl + ' ');

      let setCoursesAllergens = new Set<number>();
      item.menuItemContents.forEach((c)=>{
        c.course.course_Allergens.forEach((ca) =>
        {
          setCoursesAllergens.add(ca.allergenId);
        });
      });

      let newMenuItemId  = {
        id: item.id ,
         courses: courses ,
         alergens:[...setCoursesAllergens]
      };

      this.store.dispatch(upsertMenuItemId({menuItemId : newMenuItemId}));

    });
  }

/**  add the items in the store to this menu */
  async pasteMenuItems(){
  //pastemenuitems ->
  //Get all the items from the store
  //Post this and the current data
  // export interface CopyMenuItems {
  //   menu: Menu;
  //   menuItemsId: number[];
  // }
  // pasteMenuItems(copyMenuItems: CopyMenuItems ){
  //   return this.httpClient
  //   .post<Menu>(`${this.url}api/PasteMenuItems`, copyMenuItems)
  //   .pipe(map((res) => <Menu>res));
  // }

  if (this.security.notLoggedIn()) return;

    let menuItemsId : number[] = [];


  if (!this.menu) {
    let menu: Menu = new Menu();
    menu.menuDate = this.date;
    menu.restaurantId = this.selectedRestaurant.id;

    this.webService.addMenu(menu).subscribe((data) => {
      menu = data;
      let copyMenuItems: CopyMenuItems =  {menu, menuItemsId };

      this.store.select('menuItemIds').subscribe(x => {
        //* ERROR X is undefined */
        let numArray = x.ids;
        copyMenuItems.menuItemsId = toNumbers(numArray);

      this.webService.pasteMenuItems(copyMenuItems)
      .subscribe(res => this.ngOnInit() );
      })
    });
  } else {
    let copyMenuItems: CopyMenuItems =  {menu:this.menu, menuItemsId };
    this.store.select('menuItemIds').subscribe(x => {
      let numArray = toNumbers(x.ids);
      copyMenuItems.menuItemsId = numArray;

    this.webService.pasteMenuItems(copyMenuItems)
    .subscribe(res => this.ngOnInit() );
    })
  }

}
/** clear all items in the store */
clearMenuItems(){
  //if there are items in the store
  //dispath  ??? clearMenuItemIds
  this.store.dispatch(clearMenuItemIds());
}

  printpreview() {
    alert('navi');
  }

  convertDate(datum: Date): string {
    return this.datePipe.transform(datum, 'yyyy-MM-dd');
  }

  getKeukenType(type : number): string{
    if (type===1){return 'warme keuken';}
    else {return 'koude keuken'}
  }

  changeKeuken(ob){
    let selectedKeuken = ob.value;
    this.menu.keukenType = selectedKeuken;
    this.webService.saveMenuKeukenType(this.menu)
    .subscribe(data => this.menu = data );
    //alert(selectedKeuken);

  }
}
