<img
  *ngIf="!large"
  src="{{ url }}images/{{ courseLogo?.logo }}"
  ngbPopover="{{ courseLogoName }}"
  style="width: 50px"
  [openDelay]="300"
  [closeDelay]="500"
  triggers="mouseenter:mouseleave"
  containerClass="customClass"
/>
<img
  *ngIf="large"
  src="{{ url }}images/{{ courseLogo?.logo }}"
  style="width: 180px"
  ngbPopover="{{ courseLogoName }}"
  [openDelay]="300"
  [closeDelay]="500"
  triggers="mouseenter:mouseleave"
  containerClass="customClass"
/>
