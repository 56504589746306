import { Component, OnInit } from "@angular/core";
import { Allergen } from "../../models/allergen";
import { WebService } from "../../core/web.service";


@Component({
  selector: "app-allergens",
  templateUrl: "./allergens.component.html",
  styleUrls: ["./allergens.component.css"]
})
export class AllergensComponent implements OnInit {

  constructor(public webService: WebService) {}

  allergens: Allergen[];

  ngOnInit() {
    //get all the allergens
    this.webService.getAllergens().subscribe(
      data => this.setAllergens(data));

  }

  setAllergens(data) {
    this.allergens = data;
  }
}
