import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '~auth/auth-guard.service';

import { LoginComponent } from '~auth/login/login.component';
import { UsersComponent } from './admin/users/users.component';
import { MenuitemCompositionComponent } from './calendar/menuitem-composition/menuitem-composition.component';

export const routes: Routes = [
  { path: '', redirectTo: '/kalender', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, outlet: 'side' },
  {
    path: 'gerechten',
    loadChildren: () =>
      import('./courses/courses.module').then((m) => m.CoursesModule),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./courses/courses.module').then((m) => m.CoursesModule),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'ingredienten',
    loadChildren: () =>
      import('./ingredients/ingredients.module').then(
        (m) => m.IngredientsModule
      ),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'ingredients',
    loadChildren: () =>
      import('./ingredients/ingredients.module').then(
        (m) => m.IngredientsModule
      ),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'course',
    loadChildren: () =>
      import('./course/course.module').then((m) => m.CourseModule),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'gerecht',
    loadChildren: () =>
      import('./course/course.module').then((m) => m.CourseModule),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'ingredient',
    loadChildren: () =>
      import('./ingredient/ingredient.module').then((m) => m.IngredientModule),
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'kalender',
    loadChildren: () =>
      import('./calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'print',
    loadChildren: () =>
      import('./print/print.module').then((m) => m.PrintModule),
  },
  {
    path: 'tools',
    loadChildren: () =>
      import('./tools/tools.module').then((m) => m.ToolsModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: { roles: 'Admin' }
  },
  {
    path: 'mic/:id',
    component: MenuitemCompositionComponent,
    canActivate: [AuthGuard],
    data: { roles: 'Admin,Chef,Keuken' }
  }];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
