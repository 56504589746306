import { Component, OnInit, Input } from '@angular/core';
import { WebService } from '../../core/web.service';
import { Restaurant } from '../../models/restaurant';
import { Security } from '~auth/security';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css'],
})
export class RestaurantComponent implements OnInit {
  edit: boolean = false;
  @Input() restaurant: Restaurant;
  originalRestaurant: Restaurant;
  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {}
  changeEdit() {
    this.edit = true;
    this.originalRestaurant = Object.assign({}, this.restaurant);
  }
  cancelEdit() {
    this.restaurant = this.originalRestaurant;
    this.edit = false;
  }
  saveEdit() {
    if (this.security.notLoggedIn()) return;

    this.webService.updateRestaurant(this.restaurant).subscribe((data) => {
      this.restaurant = data;
      this.edit = false;
    });
    this.edit = false;
  }
  changeShowEnabled() {
    this.restaurant.enabled = !this.restaurant.enabled;
  }
  getKeukenType(type : number): string{
    if (type===1){return 'warme keuken';}
    else {return 'koude keuken'}
  }
}
