import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import { CourseCompositionVm } from '~models/course-composition-vm';
import { CourseComposition } from '~models/course-composition';
import { WebService } from '~core/web.service';
import { Security } from '~auth/security';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-course-composition-detail',
  templateUrl: './course-composition-detail.component.html',
  styleUrls: ['./course-composition-detail.component.css'],
})
export class CourseCompositionDetailComponent implements OnInit {
  @Input() level: number;
  @Input() ccvm: CourseCompositionVm;
  @Input() edit: boolean = false;

  @Output() refreshData = new EventEmitter();

  expanded: boolean = true;
  grForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public webService: WebService,
    public security: Security
  ) {}

  ngOnInit() {
    this.grForm = this.fb.group({
      grammage: [this.ccvm?.amount],
    });
    this.grForm.controls['grammage'].markAsPristine;
  }

  ngOnChanges() {}

  switch() {
    this.expanded = !this.expanded;
  }

  removeItem() {
    if (this.security.notLoggedIn()) return;
    //item to remove = ccvm.subId
    //item to removefrom = ccvm.id

    //removeCourseComposition(CCVM
    let cc: CourseComposition = {
      id: this.ccvm.id,
      subId: this.ccvm.subId,
      type: this.ccvm.type,
      amount: this.ccvm.amount,
    };
    this.webService
      .removeCourseOrIngredientFromCourse(cc)
      .subscribe((data) => this.refreshData.emit('update'));

    //this.refreshData.emit("update");
  }

  public saveGrammage() {
    this.ccvm.amount = this.grForm.controls['grammage'].value;
    //save this item.
    //
    let cc : CourseComposition = <CourseComposition> ({
      id: this.ccvm.id ,
      subId: this.ccvm.subId,
      amount: this.ccvm.amount
    });

    this.webService
    .updateCourseOrIngredientAmmount(cc)
    .subscribe();

    this.grForm.controls['grammage'].markAsPristine();

  }
  public resetGrammage() {
    this.grForm = this.fb.group({
      grammage: [this.ccvm?.amount],
    });
  }
}
