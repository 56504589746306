<div class="row hoverable" style="padding-top: 4px; padding-bottom: 4px">
  <div class="col-3">
    <mat-icon *ngIf="expanded && ccvm.type === 1" (click)="switch()"
      >expand_more</mat-icon
    >
    <mat-icon *ngIf="!expanded && ccvm.type === 1" (click)="switch()"
      >chevron_right</mat-icon
    >
    <a [routerLink]="['/course', ccvm.subId]" *ngIf="ccvm.type === 1">{{
      ccvm.nameNl
    }}</a>
    <a [routerLink]="['/ingredient', ccvm.subId]" *ngIf="ccvm.type != 1">{{
      ccvm.nameNl
    }}</a>
  </div>
  <div class="col-2">
    <app-allergen-icon
      *ngFor="let al of ccvm?.allergenIds"
      [id]="al"
    ></app-allergen-icon>
  </div>
  <div class="col-2">
    <app-moretext
      *ngIf="ccvm?.type === 2"
      [extra]="'meer'"
      [maxshortlength]="25"
      [short]="true"
      [fulltext]="ccvm?.ingredientContains"
    >
    </app-moretext>
  </div>
  <div class="col-2 small" *ngIf="edit">
    <form class="edit-grammage" [formGroup]="grForm">
    <input
    formControlName="grammage"
    type="text"  style="width:150px;" />

    <mat-icon
      *ngIf="grForm.controls['grammage'].dirty"
      (click)="saveGrammage()" style="cursor: pointer;color:green;"
      >check</mat-icon
    >

    <mat-icon
    *ngIf="grForm.controls['grammage'].dirty"
    (click)="resetGrammage()" style="cursor: pointer;color:red;"
      >cancel</mat-icon
    >
    </form>
  </div>

  <div class="col-2 small" *ngIf="!edit">{{ ccvm?.amount }}</div>
  <div class="col-1 small">
    {{ ccvm?.vendorCode }}
  </div>
  <div class="col-1">
    <mat-icon
      *ngIf="edit && level < 1"
      (click)="removeItem()"
      style="cursor: pointer"
      >delete</mat-icon
    >
  </div>
</div>
<app-course-composition
  *ngIf="ccvm.type === 1 && expanded"
  [ccid]="+ccvm?.subId"
  [level]="level + 1"
></app-course-composition>
