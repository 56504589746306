import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { WebService } from '~core/web.service';
import { Ingredient } from '~models/ingredient';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Security } from '~auth/security';
import { Ingredient_Allergen } from '~models/ingredient_allergen';
import { Allergen } from '~models/allergen';
import { Ingredient_CategoryForIngredient } from '~models/ingredient_category-for-ingredient';
import { CategoryForIngredient } from '~models/category-for-ingredient';
import { Course } from '~models/course';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Allergenchecked } from '../viewmodels/allergenchecked';

import { CategoryForIngredientChecked } from '../viewmodels/category-for-ingredient-checked';

@Component({
  selector: 'app-ingredient',
  templateUrl: './ingredient.component.html',
  styleUrls: ['./ingredient.component.css'],
})
export class IngredientComponent implements OnInit {
  sendId: number;
  ing: Ingredient;
  edit: boolean = false;
  allergenschecked: Allergenchecked[];
  closeResult: string;
  categoryForIngredients: CategoryForIngredient[];
  categoryForIngredientsChecked: CategoryForIngredientChecked[] = [];
  coursesUsingIngredient: Course[] = [];

  constructor(
    public webService: WebService,
    private route: ActivatedRoute,
    public security: Security,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.sendId = this.route.snapshot.params['id'];
    if (this.sendId != 0) {
      this.LoadData();
    } else {
      this.SetEmptyIng();
      this.edit = true;
    }
    //getAllCategories to add to an ingredient
    this.webService
      .getCategoryForIngredients()
      .subscribe((data) => (this.categoryForIngredients = data));
  }

  SetEmptyIng() {
    let ingAl: Ingredient_Allergen[];
    let ingCat: Ingredient_CategoryForIngredient[];

    this.ing = {
      id: 0,
      dispNameNl: '',
      dispNameEn: '',
      nameNl: '',
      nameEn: '',
      contains: '',
      vendorCode: '',
      deleted: false,
      enabled: true,
      ingredient_Allergens: ingAl,
      ingredient_CategoryForIngredients: ingCat,
    };
  }

  LoadData() {
    this.webService.getIngredientPlus(this.sendId).subscribe(
      (data) => this.SetIngredient(data),
      () => console.log('ok')
    );
  }

  LoadCategoryForIngredients() {
    this.webService.getCategoryForIngredients().subscribe((data) => {
      this.categoryForIngredientsChecked = data;
      for (var obja of this.categoryForIngredientsChecked) {
        obja.added = false;
      }
      for (var obj of this.ing.ingredient_CategoryForIngredients) {
        let cfic: CategoryForIngredientChecked = this.categoryForIngredientsChecked.find(
          (x) => x.id == obj.categoryForIngredientId
        );
        if (cfic) cfic.added = true;
      }
    });
  }

  SetIngredient(data) {
    this.ing = data;
    this.LoadAllergens();
    this.LoadCoursesUsingThisIngredient();
  }

  LoadAllergens() {
    this.webService
      .getAllergens()
      .subscribe((data) => this.LoadAllergensPart2(data));
  }

  LoadAllergensPart2(data) {
    this.allergenschecked = data;
    for (var obja of this.allergenschecked) {
      obja.checked = false;
    }
    for (var obj of this.ing.ingredient_Allergens) {
      let ac: Allergenchecked = this.allergenschecked.find(
        (x) => x.id == obj.allergenId
      );
      if (ac) ac.checked = true;
    }
    let ic = this.allergenschecked;
  }

  LoadCoursesUsingThisIngredient() {
    this.webService
      .getAllCoursesUsingIngredient(this.sendId)
      .subscribe((data) => (this.coursesUsingIngredient = data));
  }

  SwitchEdit() {
    this.edit = !this.edit;
  }

  Save() {
    if (this.security.notLoggedIn()) return;

    //check if valid
    if (this.ing.nameNl == '') {
      alert('Gelieve Naam  in te vullen');
      return;
    }
    if (this.ing.dispNameNl == '') {
      alert('Gelieve Productnaam in te vullen');
      return;
    }
    this.edit = false;
    this.webService.updateIngredient(this.ing).subscribe((data) => {
      this.ing = data;
      this.router.navigate(['/ingredient/', this.ing.id]);
      this.sendId = this.ing.id;
      this.LoadAllergens();
    });
  }

  Stop() {
    this.edit = false;
    this.LoadData();
  }

  updateLocation(newIngredient: Ingredient) {
    if (this.sendId == 0) {
      this.ing = newIngredient;
      // let newpath = "/ingredients/" + this.ing.id.toString();
      this.router.navigate(['/ingredients/', this.ing.id]);
    } else {
      this.ing = newIngredient;
    }
  }

  RemoveAllergen(id: number) {
    this.ing.ingredient_Allergens.splice(
      this.ing.ingredient_Allergens.indexOf(
        this.ing.ingredient_Allergens.find((x) => x.allergenId == id)
      ),
      1
    );
  }

  open(content) {
    if (this.sendId == 0) {
      alert(
        'U kan slechts allergenen aanpassen na deze data opgeslagen te hebben.'
      );
      return;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changeAllergen(al: Allergen) {
    //find the allergen id in the ingredient_allergens
    //if found - remove
    //if not found - add
    if (this.ing.ingredient_Allergens.find((x) => x.allergenId == al.id)) {
      //defined
      this.ing.ingredient_Allergens.splice(
        this.ing.ingredient_Allergens.indexOf(
          this.ing.ingredient_Allergens.find((x) => x.allergenId == al.id)
        ),
        1
      );
    } else {
      //undefined
      let ia: Ingredient_Allergen = {
        allergenId: al.id,
        ingredientId: +this.sendId,
      };
      this.ing.ingredient_Allergens.push(ia);
    }
  }

  //Start Ng Bootstrap typeahead
  model: any;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.categoryForIngredients
              .filter(
                (v) => v.nameNl.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  iformatter = (x: { nameNl: string }) => x.nameNl;
  rformatter = (result: CategoryForIngredient) => result.nameNl;
  //End  Ng Bootstrap typeahead

  //click add categorie to product
  //check if the cat is not already added
  addCatToIngredient() {
    let C_CFI: Ingredient_CategoryForIngredient = new Ingredient_CategoryForIngredient();
    C_CFI.categoryForIngredientId = this.model.id;
    C_CFI.ingredientId = this.ing.id;
    let c: CategoryForIngredient = this.categoryForIngredients.find(
      (x) => x.id == this.model.id
    );
    C_CFI.categoryForIngredient = c;

    if (
      this.ing.ingredient_CategoryForIngredients.find(
        (x) => x.categoryForIngredientId == C_CFI.categoryForIngredientId
      )
    ) {
    } else {
      this.ing.ingredient_CategoryForIngredients.push(C_CFI);
    }
  }

  iets() {
    alert('alert');
  }

  //click remove categorie from product.
  //just remove
  removeCategoryForIngredient(cfc: CategoryForIngredient) {
    let c_cfi = this.ing.ingredient_CategoryForIngredients.find(
      (x) => x.categoryForIngredientId == cfc.id
    );
    this.ing.ingredient_CategoryForIngredients.splice(
      this.ing.ingredient_CategoryForIngredients.indexOf(c_cfi),
      1
    );
    let cfic = this.categoryForIngredientsChecked.find((x) => x.id == cfic.id);
    cfic.added = false;
  }
}
