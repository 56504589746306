import { Component, OnInit } from '@angular/core';
import { WebService } from '~core/web.service';
import { Security } from '~auth/security';
import { Observable } from 'rxjs';
import { User } from '~models/user';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  users: User[];
  newuser: User = new User();

  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {
    this.webService.getUsers().subscribe((data) => this.setData(data));
  }

  setData(data) {
    this.users = data;
  }

  addNew() {
    if (this.security.notLoggedIn()) return;

    this.newuser.id = 0;
    let user: User = new User();
    this.webService.updateUser(this.newuser).subscribe((data) => {
      user = data;
      this.users.push(user);
      this.newuser = new User();
    });
  }
}
