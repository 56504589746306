import { Component, OnInit } from '@angular/core';
import { WebService } from '../../core/web.service';
import { Observable } from 'rxjs';
import { Restaurant } from '../../models/restaurant';
import { Security } from '~auth/security';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.css'],
})
export class RestaurantsComponent implements OnInit {
  restaurants: Restaurant[];
  newRestaurant: Restaurant = new Restaurant();

  constructor(public webService: WebService, public security: Security) {}

  ngOnInit() {
    this.webService
      .getRestaurants()
      .subscribe((data) => (this.restaurants = data));
    this.newRestaurant.name = '';
  }
  addNew() {
    if (this.security.notLoggedIn()) return;

    this.newRestaurant.id = 0;
    let restaurant: Restaurant = new Restaurant();
    this.webService.addRestaurant(this.newRestaurant).subscribe((data) => {
      restaurant = data;
      this.restaurants.push(restaurant);
      this.newRestaurant = new Restaurant();
    });
  }
}
